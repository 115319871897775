import { Link, Navigate } from 'react-router-dom';

import { useFormik } from 'formik';
import { StepperContexts } from './contexts/StepperContexts';
import { useContext, useState } from 'react';
import Dropzone from 'react-dropzone';
import DatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';
export default function Payment() {
  const { userData, setUserData } = useContext(StepperContexts);

  const state = useSelector((state) => state?.users);
  console.log('pp', state);

  const { userAuth } = state;

  const handleChange = (e) => {
    const { name, value } = e.target;

    console.log(e.target.value);
    setUserData({ ...userData, [name]: value, createdBy: userAuth?._id });
  };

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const [autoDay, setAutoDay] = useState('');

  const handleDateChange = (name) => {
    setDateRange(name);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    //console.log(new Date().toLocaleDateString('en-US', options));

    console.log('date', Date.now());

    let x = new Date(name[0].toLocaleDateString('en-US', options.day));
    let y = new Date(name[1].toLocaleDateString('en-US', options.day));

    let Difference_In_Time = y - x;
    let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

    Difference_In_Time = y.getMonth() - x.getMonth();

    let total_months =
      (y.getFullYear() - x.getFullYear()) * 12 + (y.getMonth() - x.getMonth());

    setAutoDay(total_months);
    console.log(total_months);
    setUserData({ ...userData, deadline: y, duration: total_months });
  };

  return (
    <div className='flex  flex-col my-6 px-8 gap-x-2'>
      <label className='block mb-2 text-sm font-medium text-gray-700  '>
        Payment Bracket
      </label>
      <div className='flex  justify-center flex-row gap-x-8'>
        <div className='mb-6 items-center text-center   '>
          <label className='block mb-2 text-sm font-medium text-gray-700  '>
            Normal
          </label>{' '}
          <input
            checked={userData?.payment === 'normal'}
            value='normal'
            onChange={handleChange}
            type='radio'
            name='payment'
            className='w-4 h-4 bg-gray-50 rounded border border-gray-300 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800'
            placeholder='First Name Last Name'
            required
          />
        </div>
        <div className='mb-6 items-center text-center '>
          <label className='block mb-2 text-sm font-medium text-gray-700  '>
            Sponsored
          </label>

          <input
            checked={userData?.payment === 'sponsored'}
            value='sponsored'
            onChange={handleChange}
            type='radio'
            name='payment'
            className='w-4 h-4 bg-gray-50 rounded border border-gray-300 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800'
            placeholder='First Name Last Name'
            required
          />
        </div>
        <div className='mb-6 items-center text-center '>
          <label className='block mb-2 text-sm text-center font-medium text-gray-700  '>
            Customised
          </label>
          <input
            checked={userData?.payment === 'customised'}
            value='customised'
            onChange={handleChange}
            type='radio'
            name='payment'
            className='w-4 h-4 bg-gray-50 rounded border border-gray-300 focus:cgreen-300   '
            placeholder='Write Notes'
            required
          />
        </div>{' '}
      </div>{' '}
      <input
        value={userData['price']}
        onChange={handleChange}
        type='text'
        name='price'
        placeholder='Write amount'
        className='shadow-sm bg-gray-100 border border-gray-300 text-black text-sm rounded-lg outline-cyellow rounded-lg block w-2/4 mx-auto p-2.5'
        required
      />{' '}
      <label className='block mb-2 text-sm font-medium text-gray-700  '>
        Payment Method
      </label>
      <div className='mb-6 mx-auto'>
        <div className='flex items-center my-8 text-center gap-x-2 h-5'>
          <div>
            <label className='block mb-2 text-sm font-medium text-gray-400  '>
              Cash
            </label>
            <input
              checked={userData?.method === 'cash'}
              onChange={handleChange}
              value='cash'
              name='method'
              type='radio'
              className='w-4 h-4 bg-gray-50 rounded border border-gray-300 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800'
              required
            />{' '}
          </div>
          <div>
            {' '}
            <label className='block mb-2 text-sm font-medium text-gray-400  '>
              Cheque
            </label>{' '}
            <input
              checked={userData?.method === 'cheque'}
              value='cheque'
              name='method'
              onChange={handleChange}
              type='radio'
              className='w-4 h-4 bg-gray-50 rounded border border-gray-300 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800'
              required
            />
          </div>{' '}
        </div>
      </div>
      <div className='mb-6  flex gap-x-2'>
        <label className='block w-full mb-2 px-2 py-4 text-sm bg-cgreen-500 font-medium text-white  '>
          Deadline : Duration
          <span className='text-cgreen-500 bg-white px-4 py-2  ml-20 mr-8 '>
            {autoDay}
          </span>
          months
        </label>
        <div className='flex w-full flex-col'>
          <DatePicker
            className='h-12'
            selected={userData?.deadline || ''}
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            onChange={handleDateChange}
            dateFormat='dd/MM/yyy'
            excludeTimes
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode='select'
            placeholderText='Click to select a date'
            isClearable={true}
          />
        </div>
      </div>
    </div>
  );
}
