import { useDispatch, useSelector } from 'react-redux';
import { useContext, useEffect, useState } from 'react';
import { registerEventAction } from '../../../redux/slices/eventSlice';
import { StepperContexts } from './contexts/StepperContexts';
import { Navigate } from 'react-router-dom';
export default function Final() {
  const { userData, setUserData } = useContext(StepperContexts);

  const dispatch = useDispatch();
  const [isUpdated, setUpdated] = useState(false);
  const state = useSelector((state) => state?.events);
  console.log('pp', state);
  console.log('regi', state?.register);

  function handleChange() {
    //let newArray = props.push({ createdBy: createdBy });
    console.log(userData);
    dispatch(registerEventAction(userData));

    //  useEffect(() => {
    // console.log('isUpdated', isUpdated);
    console.log(state?.register?.candidate_num);
    if (state?.register?.candidate_num !== '') {
      setTimeout(function () {
        setUpdated(true);
      }, 3000);
    }

    //  if (state?.loading === false && state?.register !== null) {
    //      console.log(state);

    //    setTimeout(function () {
    // window.location.replace('/events');

    //  }, 1000);
    //}
    // }, [state]);
    // console.log(dispatch(registerEventAction(userData)));

    //  setUpdated(true);
    //
  }

  useEffect(() => {
    console.log('isUpdated', isUpdated);
  }, []);

  return (
    <div className='container md:mt-10'>
      <div className='flex flex-col   justify-around items-center  items-center'>
        <div className='bg-cgreen-200 rounded-full h-20 w-20 p-4 text-center flex justify-center text-6xl  text-white'>
          &#10003;
        </div>

        <div className='mt-3 text-xl font-semibold uppercase text-cgreen-500'>
          Click Save To Confirm Your Changes
        </div>
        <div className='text-lg font-semibold text-cyellow'>
          This action is irreversible
        </div>

        {!isUpdated ? (
          <button
            onClick={handleChange}
            className='h-10 px-5 mt-10 bg-cgreen-500 text-white transition-colors duration-150 border border-gray-300 rounded-lg focus:shadow-outline hover:bg-cyellow hover:text-white'
          >
            Please Save
          </button>
        ) : (
          <a
            href='/events'
            className='py-2 px-5 mt-10 bg-cgreen-500 text-white transition-colors duration-150 border border-gray-300 rounded-lg focus:shadow-outline hover:bg-cyellow hover:text-white'
          >
            Go To Event Lists
          </a>
        )}
      </div>
    </div>
  );
}
