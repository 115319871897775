import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { baseURL } from '../../utils/baseURL';

export const registerEventAction = createAsyncThunk(
  'event/register',
  async (event, { rejectWithValue, getState, dispatch }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      };
      console.log('event', event);
      const formData = new FormData();
      formData.append('name', event?.name);
      formData.append('dob', event?.dob);
      formData.append('cnic', event?.cnic);
      formData.append('address', event?.address);
      formData.append('mobile', event?.mobile);
      formData.append('image', event?.image);

      formData.append('martial', event?.martial);
      formData.append('notes', event?.notes);
      formData.append('price', event?.price);

      formData.append('bname', event?.bname);
      formData.append('bdob', event?.bdob);
      formData.append('bcnic', event?.bcnic);
      formData.append('bdesignation', event?.bdesignation);

      formData.append('payment', event?.payment);
      formData.append('method', event?.method);

      formData.append('rname', event?.rname);
      formData.append('rmobile', event?.rmobile);
      formData.append('rnotes', event?.rnotes);

      formData.append('rqcast', event?.rqcast);
      formData.append('rqnotes', event?.rqnotes);
      formData.append('rqprofession', event?.rqprofession);

      formData.append('duration', event?.duration);
      formData.append('deadline', event?.deadline);
      formData.append('createdBy', event?.createdBy);

      // console.log('cres', formData);

      const c_res = await axios.post(
        `${baseURL}/api/events`,
        formData,

        config
      );

      return c_res.data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const allEventsAction = createAsyncThunk(
  'events/lists',

  async (events, { rejectWithValue, getState, dispatch }) => {
    try {
      //  console.log('events', events);
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      };
      const { data } = await axios.get(`${baseURL}/api/events`, config);
      console.log('res', data);
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getEventAction = createAsyncThunk(
  'events/get',

  async (id, { rejectWithValue, getState, dispatch }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      };
      const { data } = await axios.get(`${baseURL}/api/events/${id}`, config);
      console.log('res', data);
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateStatusAction = createAsyncThunk(
  'event/status',

  async (event, { rejectWithValue, getState, dispatch }) => {
    console.log('status', event);

    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      };

      const { data } = await axios.put(
        `${baseURL}/api/events/status/${event?.id}`,
        {
          status: event?.value,
        },
        config
      );
      console.log('iddd', event?.id);
      console.log('vaaaa', event?.value);

      console.log('res', data);
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);
export const updateEventAction = createAsyncThunk(
  'event/update',

  async (event, { rejectWithValue, getState, dispatch }) => {
    console.log('event', event);
    try {
      //console.log('uidser', user.id);
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      };

      const formData = new FormData();

      formData.append('name', event?.c_name);
      formData.append('dob', event?.c_dob);
      formData.append('cnic', event?.c_cnic);
      formData.append('address', event?.c_address);
      formData.append('mobile', event?.c_mobile);
      formData.append('image', event?.image);

      formData.append('martial', event?.c_martial);
      formData.append('notes', event?.c_notes);

      formData.append('price', event?.price);
      formData.append('status', event?.status);

      formData.append('bname', event?.b_name);
      formData.append('bdob', event?.b_dob);
      formData.append('bcnic', event?.b_cnic);
      formData.append('bdesignation', event?.designation);

      formData.append('payment', event?.payment);
      formData.append('method', event?.method);

      formData.append('rname', event?.ref_name);
      formData.append('rmobile', event?.ref_mobile);
      formData.append('rnotes', event?.ref_notes);

      formData.append('rqcast', event?.req_cast);
      formData.append('rqnotes', event?.req_pro);
      formData.append('rqprofession', event?.req_notes);

      formData.append('duration', event?.duration);
      formData.append('deadline', event?.deadline);
      formData.append('createdBy', event?.createdBy);
      const { data } = await axios.put(
        `${baseURL}/api/events/${event.id}`,
        /*      {
          candidate: {
            name: event?.c_name,
            address: event?.c_address,
            cnic: event?.c_cnic,
            dob: event?.c_dob,
            image: event?.image,
            martial: event?.c_martial,
            mobile: event?.c_mobile,
            notes: event?.c_notes,
          },
          beneficiary: {
            name: event?.b_name,
            dob: event?.b_dob,
            cnic: event?.b_cnic,
            designation: event?.designation,
          },
          deadline: event?.deadline,
          duration: event?.duration,
          method: event?.method,
          payment: event?.payment,
          price: event?.price,
          referral: {
            name: event?.ref_name,
            mobile: event?.ref_mobile,
            notes: event?.ref_notes,
          },
          requirements: {
            cast: event?.req_cast,
            profession: event?.req_pro,
            notes: event?.req_notes,
          },
          status: event?.status,
        }, */ formData,
        config
      );
      console.log('res', data);
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const deleteEventAction = createAsyncThunk(
  'user/delete',

  async (id, { rejectWithValue, getState, dispatch }) => {
    const event = getState();
    console.log('asdsa', event);
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      };
      const { data } = await axios.delete(
        `${baseURL}/api/events/${id}`,
        config
      );
      console.log('res', data);
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

const eventSlices = createSlice({
  name: 'events',
  initialState: { events: '' },
  extraReducers: (builder) => {
    //Register
    builder.addCase(registerEventAction.pending, (state, action) => {
      state.loading = true;
      state.appError = undefined;
      state.serverError = undefined;
    });

    builder.addCase(registerEventAction.fulfilled, (state, action) => {
      state.loading = false;
      state.register = action?.payload;
      state.appError = undefined;
      state.serverError = undefined;
    });

    builder.addCase(registerEventAction.rejected, (state, action) => {
      state.loading = false;
      state.appError = action?.payload?.message;
      state.serverError = action?.error?.message;
    });

    //list Users
    builder.addCase(allEventsAction.pending, (state, action) => {
      state.loading = true;
      state.appError = undefined;
      state.serverError = undefined;
    });

    builder.addCase(allEventsAction.fulfilled, (state, action) => {
      state.loading = false;
      state.eventLists = action?.payload;
      state.appError = undefined;
      state.serverError = undefined;
    });

    builder.addCase(allEventsAction.rejected, (state, action) => {
      state.loading = false;
      state.appError = action?.payload?.message;
      state.serverError = action?.error?.message;
    });

    //Get User By Id
    builder.addCase(getEventAction.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(getEventAction.fulfilled, (state, action) => {
      state.loading = false;
      state.getEvent = action?.payload;

      state.appError = undefined;
      state.serverError = undefined;
    });

    builder.addCase(getEventAction.rejected, (state, action) => {
      state.loading = false;
      state.appError = action?.payload?.message;
      state.serverError = action?.error?.message;
    });

    //Update User By Id
    builder.addCase(updateEventAction.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(updateEventAction.fulfilled, (state, action) => {
      state.loading = false;
      state.updateEvent = action?.payload;

      state.appError = undefined;
      state.serverError = undefined;
    });

    builder.addCase(updateEventAction.rejected, (state, action) => {
      state.loading = false;
      state.appError = action?.payload?.message;
      state.serverError = action?.error?.message;
    });

    //Update Statys By Id
    builder.addCase(updateStatusAction.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(updateStatusAction.fulfilled, (state, action) => {
      state.loading = false;
      state.updateStatus = action?.payload;

      state.appError = undefined;
      state.serverError = undefined;
    });

    builder.addCase(updateStatusAction.rejected, (state, action) => {
      state.loading = false;
      state.appError = action?.payload?.message;
      state.serverError = action?.error?.message;
    });
    //Delete
    builder.addCase(deleteEventAction.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(deleteEventAction.fulfilled, (state, action) => {
      state.loading = false;
      state.deletedEvent = action?.payload;

      state.appError = undefined;
      state.serverError = undefined;
    });

    builder.addCase(deleteEventAction.rejected, (state, action) => {
      state.loading = false;
      state.appError = action?.payload?.message;
      state.serverError = action?.error?.message;
    });
  },
});

export default eventSlices.reducer;
