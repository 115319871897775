import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
// Components
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useFormik } from 'formik';
import { registerTemplateAction } from '../redux/slices/templateSlice';

export default function Template() {
  return (
    <div className='h-screen bg-white '>
      Asalam o Alaikum Dear Beloved, For being a part of family of Mindmatch and
      we want to join your happiness. , We would like to wish you happy
      birthday.
    </div>
  );
}
