import Stepper from './Stepper';
import StepperControls from './StepperControls';
import Candidate from './Steps/Candidate';
import { useState } from 'react';
import { StepperContexts } from './Steps/contexts/StepperContexts';
import Beneficiary from './Steps/Beneficiary';
import Final from './Steps/Final';
import Referral from './Steps/Refferal';
import Requirements from './Steps/Requirements';
import Payment from './Steps/Payment';
import { registerEventAction } from '../../redux/slices/eventSlice';
import { useDispatch } from 'react-redux';

export default function CreateEvent() {
  const [userData, setUserData] = useState('');
  const [finalData, setFinalData] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const dispatch = useDispatch();
  const steps = [
    'Candidate',
    'Beneficiary',
    'Referral',
    'Requirements',
    'Payment',
    'Complete',
  ];

  const displayStep = (step) => {
    switch (step) {
      case 1:
        return <Candidate />;
      case 2:
        return <Beneficiary />;
      case 3:
        return <Referral />;
      case 4:
        return <Requirements />;
      case 5:
        return <Payment />;
      case 6:
        return <FinalC />;
      default:
    }
  };

  const FinalC = () => {
    console.log('final', userData);
    return <Final props={userData} />;
  };
  const handleClick = (direction) => {
    let newStep = currentStep;

    /*  userData?.image !== undefined &&
    userData?.name !== undefined 
    direction === 'next'
      ? newStep++
      : newStep--;
*/

    if (newStep === 1) {
      userData?.image !== undefined &&
      userData?.name !== undefined &&
      userData?.dob !== undefined &&
      direction === 'next'
        ? newStep++
        : (newStep = 1 || newStep--);
    } else if (newStep === 2) {
      userData?.bname !== undefined &&
      userData?.bdob !== undefined &&
      direction === 'next'
        ? newStep++
        : newStep <= 2 && newStep--;
    } else if (newStep === 3) {
      userData?.rname !== undefined && direction === 'next'
        ? newStep++
        : newStep <= 3 && newStep--;
    } else if (newStep === 4) {
      userData?.rqcast !== undefined && direction === 'next'
        ? newStep++
        : newStep <= 4 && newStep--;
    } else if (newStep === 5) {
      userData?.payment !== undefined &&
      userData.deadline !== undefined &&
      direction === 'next'
        ? newStep++
        : newStep <= 5 && newStep--;
    }
    newStep > 0 && newStep <= steps.length && setCurrentStep(newStep);
  };
  console.log(userData);
  return (
    <div className='w-3/4   my-2 overflow-y-auto  h-screen  mx-auto shadow-xl   rounded-2xl pb-2 bg-white'>
      <div className='container horizontal  '>
        <Stepper steps={steps} currentStep={currentStep} />
        <div className='  py-8 '>
          <StepperContexts.Provider
            value={{
              userData,
              setUserData,
              finalData,
              setFinalData,
            }}
          >
            {displayStep(currentStep)}
          </StepperContexts.Provider>
        </div>
      </div>
      {currentStep !== steps.length && (
        <StepperControls
          handleClick={handleClick}
          currentStep={currentStep}
          steps={steps}
        />
      )}
    </div>
  );
}
