import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { baseURL } from '../../utils/baseURL';

export const registerTemplateAction = createAsyncThunk(
  'template/register',
  async (template, { rejectWithValue, getState, dispatch }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const res = await axios.post(
        `${baseURL}/api/template/register`,
        template,
        config
      );
      return res.data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getTemplateAction = createAsyncThunk(
  'template/get',

  async (id, { rejectWithValue, getState, dispatch }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const { data } = await axios.get(`${baseURL}/api/template`, config);
      console.log('res', data);
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateTemplateAction = createAsyncThunk(
  'template/update',

  async (template, { rejectWithValue, getState, dispatch }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const { data } = await axios.put(
        `${baseURL}/api/template`,
        {
          //  name: user?.name,
        },
        config
      );
      console.log('res', data);
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

const templateSlices = createSlice({
  name: 'template',
  initialState: {},
  extraReducers: (builder) => {
    //Register
    builder.addCase(registerTemplateAction.pending, (state, action) => {
      state.loading = true;
      state.appError = undefined;
      state.serverError = undefined;
    });

    builder.addCase(registerTemplateAction.fulfilled, (state, action) => {
      state.loading = false;
      state.register = action?.payload;
      state.appError = undefined;
      state.serverError = undefined;
    });

    builder.addCase(registerTemplateAction.rejected, (state, action) => {
      state.loading = false;
      state.appError = action?.payload?.message;
      state.serverError = action?.error?.message;
    });

    //Get User By Id
    builder.addCase(getTemplateAction.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(getTemplateAction.fulfilled, (state, action) => {
      state.loading = false;
      state.getTemplate = action?.payload;

      state.appError = undefined;
      state.serverError = undefined;
    });

    builder.addCase(getTemplateAction.rejected, (state, action) => {
      state.loading = false;
      state.appError = action?.payload?.message;
      state.serverError = action?.error?.message;
    });

    //Update User By Id
    builder.addCase(updateTemplateAction.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(updateTemplateAction.fulfilled, (state, action) => {
      state.loading = false;
      state.updateTemplate = action?.payload;

      state.appError = undefined;
      state.serverError = undefined;
    });

    builder.addCase(updateTemplateAction.rejected, (state, action) => {
      state.loading = false;
      state.appError = action?.payload?.message;
      state.serverError = action?.error?.message;
    });
  },
});

export default templateSlices.reducer;
