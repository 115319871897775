import { Link, useLocation, Navigate, useParams } from 'react-router-dom';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, useCallback } from 'react';
import {
  getUserAction,
  updateUserAction,
} from '../../redux/slices/Users/userSlices';
export default function Profile() {
  const { id } = useParams();
  console.log(id);

  const dispatch = useDispatch();

  function runDispatch() {
    dispatch(getUserAction(id));
  }
  const fetchBusinesses = useCallback(() => {
    runDispatch();
  }, []);
  useEffect(() => {
    fetchBusinesses();
  }, [fetchBusinesses]);

  console.log('propsid', id);

  //select state from store

  const state = useSelector((state) => state?.users);
  console.log('pp', state);

  const { getUser, loading, updateUser } = state;

  const [isUpdated, setUpdated] = useState(false);

  //formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: getUser?.id,
      name: getUser?.name,
      email: getUser?.email,
      macAddress: getUser?.macAddress,
      role: getUser?.role,
      isBlocked: getUser?.isBlocked ? 'true' : 'false',
    },
    onSubmit: (values) => {
      console.log(values);
      dispatch(updateUserAction(values));
      setTimeout(function () {
        setUpdated(true);
      }, 1000);
    },
  });
  if (isUpdated) {
    console.log('isEdited', isUpdated, updateUser);

    return <Navigate to='/users' />;
  }

  return (
    <div className='container  '>
      <div className=' mt-8 h-full bg-white rounded max-w-7xl  p-8  mx-auto'>
        <h1 className='text-2xl bg-white p-4 text-center font-medium text-gray-600 w-full'>
          Manage User Profile
        </h1>
        <form className=' ' onSubmit={formik.handleSubmit}>
          <div className='mb-6'>
            <label className='block mb-2 text-sm font-medium text-gray-700  '>
              Name
            </label>
            <input
              value={formik.values.name}
              onChange={formik.handleChange('name')}
              onBlur={formik.handleBlur('name')}
              type='text'
              id='name'
              className='shadow-sm bg-cgray border border-gray-300 text-black text-sm rounded-lg  rounded-lg block w-full p-2.5'
              placeholder='First Name Last Name'
              required
            />
          </div>
          <div className='mb-6'>
            <label className='block mb-2 text-sm font-medium text-gray-700  '>
              Email
            </label>
            <input
              value={formik.values.email}
              onChange={formik.handleChange('email')}
              onBlur={formik.handleBlur('email')}
              type='email'
              id='email'
              disabled
              className='shadow-sm bg-cgray border border-gray-300 text-black text-sm rounded-lg  rounded-lg block w-full p-2.5'
              placeholder='your@email.com'
              required
            />
          </div>
          {/*     <div className='mb-6'>
            <label className='block mb-2 text-sm font-medium text-gray-700  '>
              Password
            </label>
            <input
              value={formik.values.password}
              onChange={formik.handleChange('password')}
              onBlur={formik.handleBlur('password')}
              type='password'
              id='password'
              className='shadow-sm bg-cgray border border-gray-300 text-black text-sm rounded-lg  rounded-lg block w-full p-2.5'
              required
            />
          </div> */}
          <div className='mb-6'>
            <label className='block mb-2 text-sm font-medium text-gray-700  '>
              Mac Address
            </label>
            <input
              value={formik.values.macAddress}
              onChange={formik.handleChange('macAddress')}
              onBlur={formik.handleBlur('macAddress')}
              type='text'
              id='mac-address'
              className='shadow-sm bg-cgray border border-gray-300 text-black text-sm rounded-lg  rounded-lg block w-full p-2.5'
              required
            />
          </div>
          <div className='flex flex-row justify-between'>
            <div className='flex items-start mb-6'>
              <div className='mr-8 text-sm'>
                <label className='block mb-2 text-sm font-medium text-gray-400  '>
                  Role
                </label>
              </div>
              <div className='flex items-center text-center gap-x-2 h-5'>
                <div>
                  <label className='block mb-2 text-sm font-medium text-gray-400  '>
                    Admin
                  </label>
                  <input
                    {...formik.getFieldProps('role')}
                    value='Admin'
                    checked={formik.values.role === 'Admin'}
                    id='admin'
                    aria-describedby='admin'
                    type='radio'
                    className='w-4 h-4 bg-gray-50 rounded border border-gray-300 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800'
                    required
                  />
                </div>
                <div>
                  <label
                    for='manager'
                    className='block mb-2 text-sm font-medium text-gray-400  '
                  >
                    Role Manager
                  </label>
                  <input
                    {...formik.getFieldProps('role')}
                    value='Manager'
                    checked={formik.values.role === 'Manager'}
                    id='manager'
                    aria-describedby='manager'
                    type='radio'
                    className='w-4 h-4 bg-gray-50 rounded border border-gray-300 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800'
                    required
                  />
                </div>
                <div>
                  <label className='block mb-2 text-sm font-medium text-gray-400  '>
                    Employee
                  </label>
                  <input
                    {...formik.getFieldProps('role')}
                    checked={formik.values.role === 'Employee'}
                    value='Employee'
                    id='terms'
                    aria-describedby='terms'
                    type='radio'
                    className='w-4 h-4 bg-gray-50 rounded border border-gray-300 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800'
                    required
                  />
                </div>
              </div>
            </div>

            <div className='flex items-start mb-6'>
              <div className='mr-8 text-sm'>
                <label className='block mb-2 text-sm font-medium text-gray-400  '>
                  isBlocked?
                </label>
              </div>
              <div className='flex items-center text-center gap-x-2 h-5'>
                <div>
                  <label className='block mb-2 text-sm font-medium text-gray-400  '>
                    Yes
                  </label>
                  <input
                    {...formik.getFieldProps('isBlocked')}
                    value='true'
                    checked={formik.values.isBlocked === 'true'}
                    id='isBlockedtrue'
                    aria-describedby='isBlockedtrue'
                    type='radio'
                    className='w-4 h-4 bg-gray-50 rounded border border-gray-300 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800'
                    required
                  />
                </div>
                <div>
                  <label className='block mb-2 text-sm font-medium text-gray-400  '>
                    No
                  </label>
                  <input
                    {...formik.getFieldProps('isBlocked')}
                    checked={formik.values.isBlocked === 'false'}
                    value='false'
                    id='isBlockedfalse'
                    aria-describedby='isBlockedflase'
                    type='radio'
                    className='w-4 h-4 bg-gray-50 rounded border border-gray-300 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800'
                    required
                  />
                </div>
              </div>
            </div>
          </div>

          <button
            type='submit'
            className='text-white bg-cgreen-400 w-full  hover:bg-cgreen-300 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-4 text-center  '
          >
            Update User Profile
          </button>
        </form>
      </div>
    </div>
  );
}
