import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { baseURL } from '../../../utils/baseURL';

export const registerCandidateAction = createAsyncThunk(
  'candidate/register',
  async (candidate, { rejectWithValue, getState, dispatch }) => {
    try {
      console.log('candidate', candidate);
      const formData = new FormData();
      formData.append('name', candidate?.name);
      formData.append('dob', candidate?.dob);
      formData.append('cnic', candidate?.cnic);
      formData.append('address', candidate?.address);
      formData.append('mobile', candidate?.mobile);
      formData.append('image', candidate?.image);
      console.log('f', formData);

      console.log('slic', formData);

      const res = await axios.post(`${baseURL}/api/candidates`, formData);
      return res.data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const allCandidatesAction = createAsyncThunk(
  'candidates/lists',

  async (candidates, { rejectWithValue, getState, dispatch }) => {
    try {
      console.log('candidates', candidates);
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const { data } = await axios.get(`${baseURL}/api/candidates`, config);
      console.log('res', data);
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getCandidateAction = createAsyncThunk(
  'candidate/get',

  async (id, { rejectWithValue, getState, dispatch }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const { data } = await axios.get(`${baseURL}/api/users/${id}`, config);
      console.log('res', data);
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateCandidateAction = createAsyncThunk(
  'candidate/update',

  async (candidate, { rejectWithValue, getState, dispatch }) => {
    const user = getState()?.users;
    console.log('updateCandidate', candidate);
    const { userAuth } = user;
    try {
      console.log('uidser', user.id);
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const { data } = await axios.put(
        `${baseURL}/api/users/${user.id}`,
        {
          name: user?.name,
          macAddress: user?.macAddress,
          role: user?.role,
          isBlocked: user?.isBlocked,
        },
        config
      );
      console.log('res', data);
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

const candidateSlices = createSlice({
  name: 'candidates',
  initialState: { candidates: '' },
  extraReducers: (builder) => {
    //Register
    builder.addCase(registerCandidateAction.pending, (state, action) => {
      state.loading = true;
      state.appError = undefined;
      state.serverError = undefined;
    });

    builder.addCase(registerCandidateAction.fulfilled, (state, action) => {
      state.loading = false;
      state.register = action?.payload;
      state.appError = undefined;
      state.serverError = undefined;
    });

    builder.addCase(registerCandidateAction.rejected, (state, action) => {
      state.loading = false;
      state.appError = action?.payload?.message;
      state.serverError = action?.error?.message;
    });

    //list Users
    builder.addCase(allCandidatesAction.pending, (state, action) => {
      state.loading = true;
      state.appError = undefined;
      state.serverError = undefined;
    });

    builder.addCase(allCandidatesAction.fulfilled, (state, action) => {
      state.loading = false;
      state.userLists = action?.payload;
      state.appError = undefined;
      state.serverError = undefined;
    });

    builder.addCase(allCandidatesAction.rejected, (state, action) => {
      state.loading = false;
      state.appError = action?.payload?.message;
      state.serverError = action?.error?.message;
    });

    //Get User By Id
    builder.addCase(getCandidateAction.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(getCandidateAction.fulfilled, (state, action) => {
      state.loading = false;
      state.getUser = action?.payload;

      state.appError = undefined;
      state.serverError = undefined;
    });

    builder.addCase(getCandidateAction.rejected, (state, action) => {
      state.loading = false;
      state.appError = action?.payload?.message;
      state.serverError = action?.error?.message;
    });

    //Update User By Id
    builder.addCase(updateCandidateAction.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(updateCandidateAction.fulfilled, (state, action) => {
      state.loading = false;
      state.updateUser = action?.payload;

      state.appError = undefined;
      state.serverError = undefined;
    });

    builder.addCase(updateCandidateAction.rejected, (state, action) => {
      state.loading = false;
      state.appError = action?.payload?.message;
      state.serverError = action?.error?.message;
    });
  },
});

export default candidateSlices.reducer;
