import { Link, Navigate } from 'react-router-dom';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { registerUserAction } from '../../redux/slices/Users/userSlices';
import { useState } from 'react';
//form scehma
const registerSchema = Yup.object({
  name: Yup.string().required('Name is required'),
  email: Yup.string().required('Email is required'),
  password: Yup.string().required('Password is required'),
  macAddress: Yup.string().required('Mac Address is required'),
});

export default function Register() {
  const dispatch = useDispatch();
  //formik
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      password: '',
      macAddress: '',
      role: 'Employee',
    },
    onSubmit: (values) => {
      console.log('register', values);
      dispatch(registerUserAction(values));
      //  alert('User Registered');
    },
  });
  //console.log(formik);

  //select state from store

  const storeData = useSelector((store) => store?.users);
  console.log(storeData);
  const { loading, appError, serverError, register } = storeData;

  console.log(loading);
  console.log(appError, serverError);
  console.log(register);

  const [success, setSuccess] = useState(false);
  //if (register?.user !== undefined) return <Navigate to='/users' />;

  return (
    <div className='container  '>
      <div className=' mt-8 h-full bg-white rounded max-w-7xl rounded p-8  mx-auto'>
        <h1 className='text-2xl bg-white p-4 text-center font-medium text-gray-600 w-full'>
          Register Your Team Member
        </h1>
        <form className=' ' onSubmit={formik.handleSubmit}>
          <div className='mb-6'>
            <label className='block mb-2 text-sm font-medium text-gray-700  '>
              Name
            </label>
            <input
              value={formik.values.name}
              onChange={formik.handleChange('name')}
              onBlur={formik.handleBlur('name')}
              type='text'
              id='name'
              className='shadow-sm bg-cgray border border-gray-300 text-black text-sm rounded-lg  rounded-lg block w-full p-2.5'
              placeholder='First Name Last Name'
              required
            />
          </div>
          <div className='mb-6'>
            <label className='block mb-2 text-sm font-medium text-gray-700  '>
              Email
            </label>
            <input
              value={formik.values.email}
              onChange={formik.handleChange('email')}
              onBlur={formik.handleBlur('email')}
              type='email'
              id='email'
              className='shadow-sm bg-cgray border border-gray-300 text-black text-sm rounded-lg  rounded-lg block w-full p-2.5'
              placeholder='your@email.com'
              required
            />
          </div>
          <div className='mb-6'>
            <label className='block mb-2 text-sm font-medium text-gray-700  '>
              Password
            </label>
            <input
              value={formik.values.password}
              onChange={formik.handleChange('password')}
              onBlur={formik.handleBlur('password')}
              type='password'
              id='password'
              className='shadow-sm bg-cgray border border-gray-300 text-black text-sm rounded-lg  rounded-lg block w-full p-2.5'
              required
            />
          </div>
          <div className='mb-6'>
            <label className='block mb-2 text-sm font-medium text-gray-700  '>
              Mac Address
            </label>
            <input
              value={formik.values.macAddress}
              onChange={formik.handleChange('macAddress')}
              onBlur={formik.handleBlur('macAddress')}
              type='text'
              id='mac-address'
              className='shadow-sm bg-cgray border border-gray-300 text-black text-sm rounded-lg  rounded-lg block w-full p-2.5'
              required
            />
          </div>
          <div className='flex items-start mb-6'>
            <div className='mr-8 text-sm'>
              <label className='block mb-2 text-sm font-medium text-gray-400  '>
                Role
              </label>
            </div>
            <div className='flex items-center text-center gap-x-2 h-5'>
              <div>
                <label className='block mb-2 text-sm font-medium text-gray-400  '>
                  Admin
                </label>
                <input
                  {...formik.getFieldProps('role')}
                  value='Admin'
                  checked={formik.values.role === 'Admin'}
                  id='admin'
                  aria-describedby='admin'
                  type='radio'
                  className='w-4 h-4 bg-gray-50 rounded border border-gray-300 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800'
                  required
                />
              </div>
              <div>
                <label
                  for='manager'
                  className='block mb-2 text-sm font-medium text-gray-400  '
                >
                  Role Manager
                </label>
                <input
                  {...formik.getFieldProps('role')}
                  value='Manager'
                  checked={formik.values.role === 'Manager'}
                  id='manager'
                  aria-describedby='manager'
                  type='radio'
                  className='w-4 h-4 bg-gray-50 rounded border border-gray-300 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800'
                  required
                />
              </div>
              <div>
                <label className='block mb-2 text-sm font-medium text-gray-400  '>
                  Employee
                </label>
                <input
                  {...formik.getFieldProps('role')}
                  checked={formik.values.role === 'Employee'}
                  value='Employee'
                  id='terms'
                  aria-describedby='terms'
                  type='radio'
                  className='w-4 h-4 bg-gray-50 rounded border border-gray-300 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800'
                  required
                />
              </div>
            </div>
          </div>
          <button
            onClick={() => setSuccess(true)}
            type='submit'
            className='text-white bg-cgreen-400 w-full  hover:bg-cgreen-100 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-4 text-center  '
          >
            Register new account
          </button>
        </form>
      </div>
    </div>
  );
}
