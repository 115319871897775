import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, useCallback } from 'react';
import { Link, Navigate, useLocation } from 'react-router-dom';

import {
  allUsersAction,
  logoutUserAction,
} from '../../redux/slices/Users/userSlices';
import { allEventsAction } from '../../redux/slices/eventSlice';

export default function AdminSidebar() {
  const dispatch = useDispatch();
  const state = useSelector((state) => state?.users);

  const events = useSelector((state) => state?.events);
  console.log('events', events);

  function runDispatch() {
    dispatch(allUsersAction());
  }

  const fetchBusinesses = useCallback(() => {
    runDispatch();
  }, []);
  useEffect(() => {
    fetchBusinesses();
  }, [fetchBusinesses]);

  const users = useSelector((state) => state?.users);
  console.log('users', users);

  let eventsCount = events?.eventLists?.length;
  let usersCount = users?.userLists?.length;

  //console.log(state);
  let userAuth = state?.userAuth;
  //console.log(userAuth);
  const router = useLocation();
  const [pathName, setPathName] = useState('');

  useEffect(() => {
    // console.log(router);
    setPathName(router.pathname.replace('/', ''));
  }, [router]);
  if (!userAuth) return <Navigate to='/login' />;

  return (
    <aside className='w-64' aria-label='Sidebar'>
      <div className='overflow-y-auto  h-full py-4 px-3 bg-cgray text-cgreen-500 border-2 border-gray-200'>
        <a
          href='http://mindmatchexperts.com'
          target='_blank'
          rel=' noreferrer'
          className='flex items-center bg-cgreen-500 pl-2.5 mb-5'
        >
          <img src='../logo.jpeg' className='h-10 mr-3  ' alt=' Logo' />
          <span className='self-center text-xl  text-white  font-semibold whitespace-nowrap  '>
            Mind Match <br></br>
          </span>
        </a>
        <ul className='space-y-2'>
          <li className=' '>
            <Link
              to='/'
              className='flex items-center p-2 text-sm font-normal  rounded-lg  hover:bg-cgreen-500  hover:text-cyellow'
            >
              <svg
                className='w-6 h-6   transition duration-75    group-hover:text-white '
                fill='currentColor'
                viewBox='0 0 20 20'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path d='M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z'></path>
                <path d='M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z'></path>
              </svg>
              <span className='ml-3   '>Dashboard</span>
            </Link>
          </li>
          <li>
            <Link
              to='/event'
              className={`flex items-center p-2 text-sm font-normal  rounded-lg  hover:bg-cgreen-500  hover:text-cyellow ${
                pathName === 'event' ? ' bg-cgreen-500  text-white' : ''
              }`}
            >
              <svg
                className='w-6 h-6   transition duration-75    group-hover:text-white '
                fill='currentColor'
                viewBox='0 0 20 20'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path d='M8.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l2-2a1 1 0 00-1.414-1.414L11 7.586V3a1 1 0 10-2 0v4.586l-.293-.293z'></path>
                <path d='M3 5a2 2 0 012-2h1a1 1 0 010 2H5v7h2l1 2h4l1-2h2V5h-1a1 1 0 110-2h1a2 2 0 012 2v10a2 2 0 01-2 2H5a2 2 0 01-2-2V5z'></path>
              </svg>
              <span className='flex-1 ml-3 whitespace-nowrap'>Add Event</span>
            </Link>
          </li>
          <li>
            <Link
              to='/events'
              className={`flex items-center p-2 text-sm font-normal  rounded-lg  hover:bg-cgreen-500  hover:text-cyellow ${
                pathName === 'events' ? ' bg-cgreen-500  text-white' : ''
              }`}
            >
              <svg
                className='flex-shrink-0 w-6 h-6  transition duration-75    group-hover:text-white  '
                fill='currentColor'
                viewBox='0 0 20 20'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path d='M8.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l2-2a1 1 0 00-1.414-1.414L11 7.586V3a1 1 0 10-2 0v4.586l-.293-.293z'></path>
                <path d='M3 5a2 2 0 012-2h1a1 1 0 010 2H5v7h2l1 2h4l1-2h2V5h-1a1 1 0 110-2h1a2 2 0 012 2v10a2 2 0 01-2 2H5a2 2 0 01-2-2V5z'></path>
              </svg>
              <span className='flex-1 ml-3 whitespace-nowrap'>Events</span>
              <span className='inline-flex justify-center items-center p-3 ml-3 w-3 h-3 text-sm font-medium text-cgray bg-cgreen-200  rounded-full   '>
                {eventsCount}
              </span>
            </Link>
          </li>
          <li>
            <Link
              to='/users'
              className='flex items-center p-2 text-sm font-normal  rounded-lg  hover:bg-cgreen-500  hover:text-cyellow'
            >
              <svg
                className='w-6 h-6   transition duration-75    group-hover:text-white '
                fill='currentColor'
                viewBox='0 0 20 20'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  d='M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z'
                  clipRule='evenodd'
                ></path>
              </svg>
              <span className='flex-1 ml-3 whitespace-nowrap'>Users</span>{' '}
              <span className='inline-flex justify-center items-center p-3 ml-3 w-3 h-3 text-sm font-medium text-cgray bg-cgreen-200 rounded-full  '>
                {usersCount}
              </span>
            </Link>
          </li>
          <li>
            <Link
              to='/deadlines'
              className={`flex items-center p-2 text-sm font-normal  rounded-lg  hover:bg-cgreen-500  hover:text-cyellow ${
                pathName === 'deadlines' ? ' bg-cgreen-500  text-white' : ''
              }`}
            >
              <svg
                className='w-6 h-6   transition duration-75    group-hover:text-white '
                fill='currentColor'
                viewBox='0 0 20 20'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  d='M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z'
                  clipRule='evenodd'
                ></path>
              </svg>
              <span className='flex-1 ml-3 whitespace-nowrap'>Deadlines</span>
            </Link>
          </li>
          <li>
            <Link
              to='/birthdays'
              className={`flex items-center p-2 text-sm font-normal  rounded-lg  hover:bg-cgreen-500  hover:text-cyellow ${
                pathName === 'birthdays' ? ' bg-cgreen-500  text-white' : ''
              }`}
            >
              <svg
                className='w-6 h-6   transition duration-75    group-hover:text-white '
                fill='currentColor'
                viewBox='0 0 20 20'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  d='M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z'
                  clipRule='evenodd'
                ></path>
              </svg>
              <span className='flex-1 ml-3 whitespace-nowrap'>
                Candidate Birthdays
              </span>
            </Link>
          </li>{' '}
          <li>
            <Link
              to='/bbirthdays'
              className={`flex items-center p-2 text-sm font-normal  rounded-lg  hover:bg-cgreen-500  hover:text-cyellow ${
                pathName === 'bbirthdays' ? ' bg-cgreen-500  text-white' : ''
              }`}
            >
              <svg
                className='w-6 h-6   transition duration-75    group-hover:text-white '
                fill='currentColor'
                viewBox='0 0 20 20'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  d='M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z'
                  clipRule='evenodd'
                ></path>
              </svg>
              <span className='flex-1 ml-3 whitespace-nowrap'>
                Beneficiary Birthdays
              </span>
            </Link>
          </li>
          <li>
            <Link
              to='/register'
              className={`flex items-center p-2 text-sm font-normal  rounded-lg  hover:bg-cgreen-500  hover:text-cyellow ${
                pathName === 'register' ? ' bg-cgreen-500  text-white' : ''
              }`}
            >
              <svg
                className='w-6 h-6   transition duration-75    group-hover:text-white '
                fill='currentColor'
                viewBox='0 0 20 20'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  d='M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z'
                  clipRule='evenodd'
                ></path>
              </svg>
              <span className='flex-1 ml-3 whitespace-nowrap'>Register</span>
            </Link>
          </li>
          <li>
            <Link
              to='/template'
              className={`flex items-center p-2 text-sm font-normal  rounded-lg  hover:bg-cgreen-500  hover:text-cyellow ${
                pathName === 'template' ? ' bg-cgreen-500  text-white' : ''
              }`}
            >
              <svg
                className='w-6 h-6   transition duration-75    group-hover:text-white '
                fill='currentColor'
                viewBox='0 0 20 20'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  d='M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z'
                  clipRule='evenodd'
                ></path>
              </svg>
              <span className='flex-1 ml-3 whitespace-nowrap'>Template</span>
            </Link>
          </li>
          <li>
            <a className='flex cursor-pointer items-center p-2 text-sm font-normal  rounded-lg  hover:bg-cgreen-500  hover:text-cyellow'>
              <svg
                className='w-6 h-6   transition duration-75    group-hover:text-white '
                fill='currentColor'
                viewBox='0 0 20 20'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  d='M5 4a3 3 0 00-3 3v6a3 3 0 003 3h10a3 3 0 003-3V7a3 3 0 00-3-3H5zm-1 9v-1h5v2H5a1 1 0 01-1-1zm7 1h4a1 1 0 001-1v-1h-5v2zm0-4h5V8h-5v2zM9 8H4v2h5V8z'
                  clipRule='evenodd'
                ></path>
              </svg>
              <span
                className='flex-1 ml-3 whitespace-nowrap'
                onClick={() => dispatch(logoutUserAction())}
              >
                Logout
              </span>
            </a>
          </li>
        </ul>
      </div>
    </aside>
  );
}
