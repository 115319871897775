import { Link, Navigate, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  allEventsAction,
  deleteEventAction,
  getEventAction,
  updateEventAction,
  updateStatusAction,
} from '../../redux/slices/eventSlice';
import { Menu, Transition } from '@headlessui/react';
import { Fragment, useRef } from 'react';
import { ChevronDownIcon } from '@heroicons/react/solid';

export default function DeadlineTemplate() {
  const { id } = useParams();
  console.log(id);

  const dispatch = useDispatch();

  function runDispatch() {
    dispatch(getEventAction(id));
  }

  useEffect(() => {
    runDispatch();
  }, []);

  const state = useSelector((state) => state?.events);
  console.log('pp', state);
  const d = new Date();
  function printDiv(divName) {
    var printContents = document.getElementById(divName).innerHTML;
    var originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;

    window.print();

    document.body.innerHTML = originalContents;
  }
  return (
    <>
      <button
        className='bg-cyellow mx-auto my-8 w-48'
        onClick={() => printDiv('printableArea')}
      >
        Print this page
      </button>
      <div id='printableArea' className='h-screen bg-white p-10'>
        <div className='flex justify-between'>
          <div className='mb-8'>
            <p className='font-bold'>Date</p>
            {d.toDateString()}
          </div>
          <div>
            <img src='/logomm.jpeg' className='w-24 mx-auto' />
          </div>
        </div>
        <p className='font-medium'>
          To Mr./Mrs. {state?.getEvent?.candidate?.name},
        </p>
        <p className='font-medium'>
          Mobile: {state?.getEvent?.candidate?.mobile},
        </p>
        <p className='font-medium'>
          Address: {state?.getEvent?.candidate?.address},
        </p>
        <p className='font-medium'>
          Candidate Number: {state?.getEvent?.candidate_num},
        </p>
        <div className=' my-12'>
          <p>
            <p className='my-6'>Assalam-o-Alaikum, </p>
            Thank you for being a part of family of Mindmatch. Mindmatch Experts
            deals in corporate events, brand activation, wedding events, award
            ceremonies, and proposal management. Events are not only limited to
            mainstream managed program themes, but rather a mixture of all
            kinds, which enables a larger gathering to light their spark. This
            includes understanding what is important to you and what personal
            qualities you value in yourself and others.
          </p>
          <p className='my-6'>
            This letter is about reminding you that your deadline has been ended
            today i-e {d.toDateString()}. We unfortunately couldn't able to find
            the perfect choice for you within your deadline duration that is{' '}
            {state?.getEvent?.duration} months. If you need to extend your
            deadline duration or have any questions, please feel free to contact
            us. If you want to terminate your contract with us, we would refund
            you 80% of your total payment.
          </p>

          <p className='my-6'>We look forward to seeing you!</p>
          <p className='my-6'>Best Regards,</p>
          <p className='my-2'>Usman Shabbir</p>
          <p className='my-2'>CEO | Founder</p>
          <p className='my-2'>Mind Match Experts</p>
          <p className='my-2'>+92 321 8969876</p>
          <p className='my-2'>www.mindmatchexperts.com</p>
        </div>
      </div>{' '}
    </>
  );
}
