import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import '@material-tailwind/react/tailwind.css';

import { useLocation } from 'react-router-dom';

import Sidebar from './components/Sidebar/Sidebar';
import { Provider } from 'react-redux';
import store from './redux/store/store';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
