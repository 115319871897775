import logo from './logo.svg';
import './App.css';

import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import Register from './components/Auth/Register';
import Dashboard from './components/Dashboard/Dashboard';
import SidebarLayout from './Outlet';
import Login from './components/Auth/Login';
import UserLists from './components/Users/UserLists';
import UserProfile from './components/Users/Profile';
import CreateEvent from './components/Events/CreateEvent';
import EventLists from './components/Events/EventLists';
import EventProfile from './components/Events/EventProfile';
import Deadlines from './components/Events/Deadlines';
import Birthdays from './components/Events/Birthdays';
import B_Birthday from './components/Events/B_Birthday';
import Template from './components/Template';
import DeadlineTemplate from './components/Events/DeadlineTemplate';
import BenefBirthdayTemplate from './components/Events/BenfBirthdayTemplate';
import CaBirthdayTemplate from './components/Events/CaBirthdayTemplate';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<SidebarLayout />}>
          <Route path='/' element={<Dashboard />} />
          <Route path='/register' element={<Register />} />
          <Route path='/event/:id' element={<EventProfile />} />

          <Route path='/event' element={<CreateEvent />} />
          <Route path='/events' element={<EventLists />} />
          <Route path='/deadlines' element={<Deadlines />} />
          <Route path='/birthdays' element={<Birthdays />} />
          <Route path='/bbirthdays' element={<B_Birthday />} />
          <Route path='/template' element={<Template />} />
          <Route path='/deadline_template/:id' element={<DeadlineTemplate />} />
          <Route
            path='/benef_birthday_template/:id'
            element={<BenefBirthdayTemplate />}
          />
          <Route
            path='/birthday_template/:id'
            element={<CaBirthdayTemplate />}
          />

          <Route path='/users' element={<UserLists />} />
          <Route path='/user/:id' element={<UserProfile />} />
        </Route>
        <Route path='/login' element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
