import { Link, Navigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  allEventsAction,
  deleteEventAction,
  updateEventAction,
  updateStatusAction,
} from '../../redux/slices/eventSlice';
import { Menu, Transition } from '@headlessui/react';
import { Fragment, useRef } from 'react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import Pagination from '../Pagination';
export default function B_Birthday() {
  const dispatch = useDispatch();

  /*const state = useSelector((state) => state?.users);
  let admin = state?.userAuth?.isAdmin;
  console.log(admin);
*/
  const [show, setShow] = useState(false);

  function runDispatch() {}
  const events = useSelector((state) => state?.events);
  console.log('sdsd', events);

  const { eventLists, appError, loading, serverError } = events;
  console.log('loading', loading);
  const users = useSelector((state) => state?.users);

  const { userLists, userAuth } = users;
  let role = userAuth?.role;
  // if

  console.log(eventLists);
  console.log(userLists);

  function DeleteId(props) {
    var ifconfirm = window.confirm('Are you sure you want to delete');
    if (ifconfirm) {
      dispatch(deleteEventAction(props));
      setShow(!show);
      window.location.reload();
    }
  }
  const [values, setValues] = useState('');
  function handleChange(id, value) {
    //alert(id);
    //alert(value);
    setValues({ ...values, id, value });
  }
  useEffect(() => {
    console.log('va', values);

    if (
      values?.value === 'Open' ||
      values?.value === 'Closed' ||
      values?.value === 'In Process'
    ) {
      dispatch(updateStatusAction(values));
    }
    dispatch(allEventsAction());
  }, [values?.value]);

  //Pagination

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = eventLists?.slice(indexOfFirstPost, indexOfLastPost);
  console.log(currentPosts);
  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  return (
    <>
      <div className=' '>
        <div>
          <div className=' w-full'>
            <div className='px-4 md:px-10 py-4 md:py-7'>
              <div className='flex items-center justify-between'>
                <p className='text-base sm:text-lg md:text-xl lg:text-2xl font-bold leading-normal text-gray-800'>
                  Beneficiary Birthdays
                </p>
              </div>
            </div>

            <div className='bg-white py-4 md:py-7 px-4 md:px-8 xl:px-10'>
              <div className='mt-7 overflow-x-auto'>
                <table className='w-full whitespace-nowrap'>
                  <thead>
                    <tr className='h-16 border border-gray-100 rounded'>
                      <th>
                        <div className='flex items-center pl-5'>
                          <p className='text-sm font-medium leading-none text-gray-700 mr-2'>
                            S.No
                          </p>
                        </div>
                      </th>
                      <th>
                        <div className='flex items-center pl-5'>
                          <p className='text-sm font-medium leading-none text-gray-700 mr-2'>
                            Candidate
                          </p>
                        </div>
                      </th>
                      <th>
                        <div className='flex items-center pl-5'>
                          <p className='text-sm font-medium leading-none text-gray-700 mr-2'>
                            Candidate Name
                          </p>
                        </div>
                      </th>
                      <th>
                        <div className='flex items-center pl-5'>
                          <p className='text-sm font-medium leading-none text-gray-700 mr-2'>
                            Mobile
                          </p>
                        </div>
                      </th>
                      <th>
                        <div className='flex items-center pl-5'>
                          <p className='text-sm font-medium leading-none text-gray-700 mr-2'>
                            Beneficiary
                          </p>
                        </div>
                      </th>
                      <th>
                        <div className='flex items-center pl-5'>
                          <p className='text-sm font-medium leading-none text-gray-700 mr-2'>
                            Beneficiary DOB
                          </p>
                        </div>
                      </th>
                      <th>
                        <div className='flex items-center pl-5'>
                          <p className='text-sm font-medium leading-none text-gray-700 mr-2'>
                            Deadline
                          </p>
                        </div>
                      </th>
                      <th>
                        <div className='flex items-center pl-5'>
                          <p className='text-sm font-medium leading-none text-gray-700 mr-2'>
                            Status
                          </p>
                        </div>
                      </th>
                      <th>
                        <div className='flex items-center pl-5'>
                          <p className='text-sm font-medium leading-none text-gray-700 mr-2'>
                            {role !== 'Admin' ? (
                              <span>View</span>
                            ) : (
                              <span>Edit</span>
                            )}
                          </p>
                        </div>
                      </th>{' '}
                      <th>
                        <div className='flex items-center pl-5'>
                          <p className='text-sm font-medium leading-none text-gray-700 mr-2'>
                            <span>Print</span>
                          </p>
                        </div>
                      </th>
                    </tr>
                  </thead>

                  {currentPosts?.map((elem, index) => (
                    <tbody key={index}>
                      {new Date(elem?.beneficiary?.dob).toLocaleString(
                        'en-us',
                        {
                          month: 'short',
                          day: 'numeric',
                        }
                      ) ===
                        new Date().toLocaleString('en-us', {
                          month: 'short',
                          day: 'numeric',
                        }) && (
                        <tr className='h-16 border border-gray-100 rounded'>
                          <td>
                            <div className='ml-5'>
                              <div className='bg-gray-200 rounded-sm w-5 h-5 flex flex-shrink-0 justify-center items-center relative'>
                                <div className='check-icon  bg-indigo-700 text-white rounded-sm'></div>
                              </div>
                            </div>
                          </td>{' '}
                          <td className='pl-5'>
                            <div className='flex items-center'>
                              <p className='text-sm   leading-none text-gray-700 mr-2'>
                                {elem?.candidate_num}
                              </p>
                            </div>
                          </td>
                          <td className>
                            <div className='flex items-center gap-x-2 pl-5'>
                              <img
                                src={elem?.candidate?.image}
                                className='w-8 h-8 rounded-full'
                              />
                              <p className='text-sm   leading-none text-gray-700 mr-2'>
                                {elem?.candidate?.name}{' '}
                              </p>
                            </div>
                          </td>
                          <td className='pl-5'>
                            <div className='flex items-center'>
                              <p className='text-sm   leading-none text-gray-700 mr-2'>
                                {elem?.candidate?.mobile}
                              </p>
                            </div>
                          </td>{' '}
                          <td className='pl-5'>
                            <div className='flex items-center'>
                              <p className='text-sm   leading-none text-gray-700 mr-2'>
                                {elem?.beneficiary?.name}
                              </p>
                            </div>
                          </td>
                          <td className='pl-5'>
                            <div className='flex items-center'>
                              <p className='text-sm   leading-none text-gray-700 mr-2'>
                                {new Date(
                                  elem?.beneficiary?.dob
                                ).toLocaleDateString()}
                              </p>
                            </div>
                          </td>
                          <td className='pl-5'>
                            <div className='py-3 px-3 text-sm focus:outline-none leading-none t  rounded'>
                              <p className='text-sm   leading-none text-gray-700 mr-2'>
                                {new Date(elem?.deadline).toLocaleDateString()}
                              </p>
                            </div>
                          </td>
                          <td className='pl-5'>
                            <div className='py-3 px-3 text-sm focus:outline-none leading-none    rounded'>
                              <div className='  '>
                                <Menu as='div' className='    '>
                                  <div className=' relative   '>
                                    <Menu.Button
                                      className={`${
                                        (elem?.status === 'Open' &&
                                          'inline-flex w-full justify-center rounded-md bg-cblue   px-4 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75') ||
                                        (elem?.status === 'In Process' &&
                                          'inline-flex w-full justify-center rounded-md bg-green-800   px-4 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75') ||
                                        (elem?.status === 'Closed' &&
                                          'inline-flex w-full justify-center rounded-md bg-red-800   px-4 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75')
                                      }  `}
                                    >
                                      {elem?.status}
                                      <ChevronDownIcon
                                        className='ml-2 -mr-1 h-5 w-5 text-violet-200 hover:text-violet-100'
                                        aria-hidden='true'
                                      />
                                    </Menu.Button>
                                  </div>
                                  <Transition
                                    as={Fragment}
                                    enter='transition ease-out duration-100'
                                    enterFrom='transform opacity-0 scale-95'
                                    enterTo='transform opacity-100 scale-100'
                                    leave='transition ease-in duration-75'
                                    leaveFrom='transform opacity-100 scale-100'
                                    leaveTo='transform opacity-0 scale-95'
                                  >
                                    <Menu.Items className='absolute z-10 right-40 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                                      <div className='px-1 py-1 '>
                                        <Menu.Item>
                                          {({ active }) => (
                                            <button
                                              onClick={() =>
                                                handleChange(elem?.id, 'Open')
                                              }
                                              className={`${
                                                active
                                                  ? 'bg-cblue text-white'
                                                  : 'text-gray-900'
                                              } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                            >
                                              Open
                                            </button>
                                          )}
                                        </Menu.Item>
                                      </div>
                                      <div className='px-1 py-1'>
                                        <Menu.Item>
                                          {({ active }) => (
                                            <button
                                              onClick={() =>
                                                handleChange(
                                                  elem?.id,
                                                  'In Process'
                                                )
                                              }
                                              className={`${
                                                active
                                                  ? 'bg-green-800 text-white'
                                                  : 'text-gray-900'
                                              } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                            >
                                              In Process
                                            </button>
                                          )}
                                        </Menu.Item>
                                      </div>{' '}
                                      <div className='px-1 py-1'>
                                        <Menu.Item>
                                          {({ active }) => (
                                            <button
                                              onClick={() =>
                                                handleChange(elem?.id, 'Closed')
                                              }
                                              className={`${
                                                active
                                                  ? 'bg-red-800 text-white'
                                                  : 'text-gray-900'
                                              } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                            >
                                              Closed
                                            </button>
                                          )}
                                        </Menu.Item>
                                      </div>
                                    </Menu.Items>
                                  </Transition>
                                </Menu>
                              </div>
                            </div>
                          </td>
                          <td className='pl-4'>
                            <Link
                              to={{
                                pathname: `/event/${elem.id}`,
                                propId: { eventId: elem.id },
                              }}
                              className='text-sm leading-none text-gray-600 py-3 px-5 bg-gray-100 rounded hover:bg-gray-200 focus:outline-none'
                            >
                              {role === 'Admin' ? (
                                <span>View/Edit</span>
                              ) : (
                                <span>View</span>
                              )}
                            </Link>
                          </td>{' '}
                          <td className='pl-4'>
                            <Link
                              to={{
                                pathname: `/benef_birthday_template/${elem.id}`,
                                propId: { eventId: elem.id },
                              }}
                              className='text-sm leading-none text-gray-600 py-3 px-5 bg-gray-100 rounded hover:bg-gray-200 focus:outline-none'
                            >
                              <span>Print</span>
                            </Link>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  ))}
                </table>
              </div>
            </div>
          </div>
          <style>
            {` .checkbox:checked + .check-icon {
                display: flex;
            }`}
          </style>
        </div>
      </div>{' '}
      <Pagination
        postsPerPage={postsPerPage}
        totalPosts={eventLists?.length}
        paginate={paginate}
        currentPage={currentPage}
      />
    </>
  );
}
