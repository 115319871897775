import { configureStore } from '@reduxjs/toolkit';
import usersReducers from '../slices/Users/userSlices';
import candidateReducers from '../slices/Candidate/candidateSlices';
import eventReducers from '../slices/eventSlice';

const store = configureStore({
  reducer: {
    users: usersReducers,
    // candidates: candidateReducers,
    events: eventReducers,
  },
});

export default store;
