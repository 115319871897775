import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { loginUserAction } from '../../redux/slices/Users/userSlices';
import { Navigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { baseURL } from '../../utils/baseURL';
//form scehma
const formSchema = Yup.object({
  name: Yup.string().required('Name is required'),
  email: Yup.string().required('Email is required'),
  password: Yup.string().required('Password is required'),
  macAddress: Yup.string().required('Mac Address is required'),
});

export default function Login() {
  const [mac, setMac] = useState('');
  useEffect(() => {
    axios.get(`${baseURL}/api/users/getmac`).then((response) => {
      console.log('ax', response);
      setMac(response?.data);
    });
  }, [mac]);
  console.log('mac', mac);
  const dispatch = useDispatch();
  //formik
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      macAddress: '',
    },

    onSubmit: (values) => {
      console.log('ccc', values);
      dispatch(loginUserAction(values));
    },
  });

  console.log(formik);

  //select state from store

  const storeData = useSelector((store) => store?.users);
  console.log(storeData);
  const { loading, appError, serverError, userAuth } = storeData;

  console.log(loading);
  console.log(appError, serverError);
  console.log(userAuth);
  if (userAuth) return <Navigate to='/' />;

  return (
    <div className=' bg-black h-screen flex flex-col justify-around mx-auto '>
      <h1 className='text-6xl  p-4 text-center font-extrabold text-white w-full'>
        Mind Matching Software
      </h1>
      <div className=' my-8 mx-auto w-full p-8 bg-white bg-gray-300 rounded   rounded max-w-4xl '>
        {' '}
        <h1 className='text-4xl    text-center font-extrabold  w-full'>
          Login
        </h1>
        {serverError == 'undefined' || appError == 'undefined' ? (
          <h2>Err</h2>
        ) : null}
        <form className=' ' onSubmit={formik.handleSubmit}>
          <div className='mb-6'>
            <label className='block mb-2 text-sm font-medium text-gray-700  '>
              Email
            </label>
            <input
              value={formik.values.email}
              onChange={formik.handleChange('email')}
              onBlur={formik.handleBlur('email')}
              type='email'
              id='email'
              className='shadow-sm bg-cgray border border-gray-300 text-black text-sm rounded-lg  rounded-lg block w-full p-2.5'
              placeholder='your@email.com'
              required
            />
          </div>
          <div className='mb-6'>
            <label className='block mb-2 text-sm font-medium text-gray-700  '>
              Password
            </label>
            <input
              value={formik.values.password}
              onChange={formik.handleChange('password')}
              onBlur={formik.handleBlur('password')}
              type='password'
              id='password'
              className='shadow-sm bg-cgray border border-gray-300 text-black text-sm rounded-lg  rounded-lg block w-full p-2.5'
              required
            />
          </div>
          <div className='mb-6'>
            <label className='block mb-2 text-sm font-medium text-gray-700  '>
              Mac Address
            </label>

            <input
              value={formik.values.macAddress}
              onChange={formik.handleChange('macAddress')}
              onBlur={formik.handleBlur('macAddress')}
              placeholder='macAddress'
              type='text'
              id='mac-address'
              className='shadow-sm bg-cgray border border-gray-300 text-black text-sm rounded-lg  rounded-lg block w-full p-2.5'
              required
            />
            {/*      <input
              value={[`${mac}`]}
              disabled={true}
              onChange={formik.handleChange(
                'macAddressFormik enableReinitialize={true}'
              )}
              //   onChange={formik.handleChange('macAddress')}
              // onBlur={formik.handleBlur('macAddress')}
              //  placeholder={mac}
              type='text'
              id='mac-address'
              className='shadow-sm bg-cgray border border-gray-300 text-black text-sm rounded-lg  rounded-lg block w-full p-2.5'
              required
              /> */}
          </div>

          <button
            type='submit'
            className='text-white bg-cgreen-400 w-full  hover:bg-cgreen-200 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-4 text-center  '
          >
            Login
          </button>
        </form>
      </div>
    </div>
  );
}
