import { Link, Navigate } from 'react-router-dom';

import { useFormik } from 'formik';
import { StepperContexts } from './contexts/StepperContexts';
import { useContext, useState } from 'react';
import Dropzone from 'react-dropzone';
export default function Referral() {
  const { userData, setUserData } = useContext(StepperContexts);

  const handleChange = (e) => {
    const { name, value } = e.target;

    console.log(e.target.value);
    setUserData({ ...userData, [name]: value });
  };

  return (
    <div className='flex  flex-col  px-8 gap-x-2'>
      <div className='my-6'>
        <label className='block mb-2 text-sm font-medium text-gray-700  '>
          Name *
        </label>
        <input
          value={userData['rname'] || ''}
          onChange={handleChange}
          type='text'
          name='rname'
          className='shadow-sm bg-gray-100 border border-gray-300 text-black text-sm rounded-lg outline-cyellow rounded-lg block w-full p-2.5'
          placeholder='First Name Last Name'
          required
        />
      </div>

      <div className='mb-6'>
        <label className='block mb-2 text-sm font-medium text-gray-700  '>
          Mobile Number
        </label>
        <input
          value={userData['rmobile'] || ''}
          onChange={handleChange}
          type='text'
          name='rmobile'
          className='shadow-sm bg-gray-100 border border-gray-300 text-black text-sm rounded-lg outline-cyellow rounded-lg block w-full p-2.5'
          placeholder='Mobile Number'
          required
        />
      </div>
      <div className='mb-6'>
        <label className='block mb-2 text-sm font-medium text-gray-700  '>
          Notes
        </label>
        <textarea
          rows='8'
          value={userData['rnotes'] || ''}
          onChange={handleChange}
          type='text'
          name='rnotes'
          className='shadow-sm bg-gray-100 border border-gray-300 text-black text-sm rounded-lg outline-cyellow rounded-lg block w-full p-2.5'
          placeholder='Write Notes'
          required
        />
      </div>
    </div>
  );
}
