import { useCallback } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { allEventsAction } from '../../redux/slices/eventSlice';
import { allUsersAction } from '../../redux/slices/Users/userSlices';

export default function Dashboard() {
  const dispatch = useDispatch();

  const fetchBusinesses = useCallback(() => {
    runDispatch();
  }, []);
  useEffect(() => {
    fetchBusinesses();
  }, [fetchBusinesses]);

  function runDispatch() {
    dispatch(allUsersAction());
    dispatch(allEventsAction());
  }
  const users = useSelector((state) => state?.users);
  console.log('users', users);

  const { userLists, appError, loading, serverError } = users;
  console.log('loading', loading);

  console.log(userLists);
  let employees = [];
  userLists?.map((el, index) => {
    if (el?.role !== 'Admin') {
      employees.push(el);
    }
  });
  const events = useSelector((state) => state?.events);
  const { eventLists } = events;
  let process = [];
  let open = [];
  let closed = [];
  eventLists?.map((el, index) => {
    if (el?.status === 'Open') {
      open.push(el);
    }
  });
  eventLists?.map((el, index) => {
    if (el?.status === 'In Process') {
      process.push(el);
    }
  });
  eventLists?.map((el, index) => {
    if (el?.status === 'Closed') {
      closed.push(el);
    }
  });
  console.log('event', eventLists);
  return (
    <div className='py-12 container h-screen max-w-7xl  p-8 text-center mx-auto'>
      <div className='grid grid-cols-3 gap-8 mx-auto'>
        <div class='flex h-36 bg-[#E3E3E2] p-4 text-cgreen-400 border-2 border-cgreen-400 rounded-lg justify-center items-center'>
          <div class='text-center '>
            <h1 class='text-xl text-cpurple'>Total Users</h1>
            <p class='text-4xl'>{userLists?.length}</p>
          </div>
        </div>
        <div class='flex h-36 bg-[#E3E3E2] p-4 text-cgreen-400 border-2 border-cgreen-400 rounded-lg justify-center items-center'>
          <div class='text-center '>
            <h1 class='text-xl text-cpurple'>Total Employees</h1>
            <p class='text-4xl'>{employees?.length}</p>
          </div>
        </div>
        <div class='flex h-36 bg-[#E3E3E2] p-4 text-cgreen-400 border-2 border-cgreen-400 rounded-lg justify-center items-center'>
          <div class='text-center '>
            <h1 class='text-xl '>Total Events</h1>
            <p class='text-4xl'>{eventLists?.length}</p>
          </div>
        </div>
        <div class='flex h-36 bg-[#E3E3E2] p-4 text-cgreen-400 border-2 border-cgreen-400 rounded-lg justify-center items-center'>
          <div class='text-center '>
            <h1 class='text-xl text-cpurple'>Open Events</h1>
            <p class='text-4xl'>{open?.length}</p>
          </div>
        </div>
        <div class='flex h-36 bg-[#E3E3E2] p-4 text-cgreen-400 border-2 border-cgreen-400 rounded-lg justify-center items-center'>
          <div class='text-center '>
            <h1 class='text-xl text-cpurple'>In Process Events</h1>
            <p class='text-4xl'>{process?.length}</p>
          </div>
        </div>
        <div class='flex h-36 bg-[#E3E3E2] p-4 text-cgreen-400 border-2 border-cgreen-400 rounded-lg justify-center items-center'>
          <div class='text-center '>
            <h1 class='text-xl text-cpurple'>Closed Events</h1>
            <p class='text-4xl'>{closed?.length}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
