import { Link, Navigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, useCallback } from 'react';
import {
  allUsersAction,
  deleteUserAction,
} from '../../redux/slices/Users/userSlices';
import {
  allEventsAction,
  deleteEventAction,
} from '../../redux/slices/eventSlice';
import Dropdown from '../ReactBlocks/Dropdown';
import Profile from './Profile';
import Pagination from '../Pagination';
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}
export default function UserLists() {
  /*const state = useSelector((state) => state?.users);
  let admin = state?.userAuth?.isAdmin;
  console.log(admin);
*/
  const [show, setShow] = useState(null);
  const dispatch = useDispatch();

  function runDispatch() {
    dispatch(allUsersAction());
    dispatch(allEventsAction());
  }

  const fetchBusinesses = useCallback(() => {
    runDispatch();
  }, []);
  useEffect(() => {
    fetchBusinesses();
  }, [fetchBusinesses]);

  const users = useSelector((state) => state?.users);
  console.log('users', users);

  const { userLists, appError, loading, serverError } = users;
  console.log('loading', loading);

  console.log(userLists);

  const events = useSelector((state) => state?.events);
  const { eventLists } = events;

  console.log('event', eventLists);

  function DeleteId(props) {
    var ifconfirm = window.confirm('Are you sure you want to delete');
    if (ifconfirm) {
      dispatch(deleteUserAction(props));
    }
  }

  //Pagination

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = userLists?.slice(indexOfFirstPost, indexOfLastPost);
  console.log(currentPosts);
  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  return (
    <>
      <div className=' '>
        <div>
          <div className=' w-full'>
            <div className='px-4 md:px-10 py-4 md:py-7'>
              <div className='flex items-center justify-between'>
                <p className='text-base sm:text-lg md:text-xl lg:text-2xl font-bold leading-normal text-gray-800'>
                  Manage Your Team
                </p>
                <div className='py-3 px-4 flex items-center text-sm font-medium leading-none text-gray-600 bg-gray-200 hover:bg-gray-300 cursor-pointer rounded'>
                  <button>
                    <Link to='/register'>Add Your Team</Link>
                  </button>
                </div>
              </div>
            </div>

            <div className='bg-white h-full py-4 md:py-7 px-4 md:px-8 xl:px-10'>
              <div className='mt-7 overflow-x-auto'>
                <table className='w-full whitespace-nowrap'>
                  <thead>
                    <tr className='h-16 border border-gray-100 rounded'>
                      <th>
                        <div className='flex items-center pl-5'>
                          <p className='text-sm font-medium leading-none text-gray-700 mr-2'>
                            S.No
                          </p>
                        </div>
                      </th>
                      <th>
                        <div className='flex items-center pl-5'>
                          <p className='text-sm font-medium leading-none text-gray-700 mr-2'>
                            Name
                          </p>
                        </div>
                      </th>
                      <th>
                        <div className='flex items-center pl-5'>
                          <p className='text-sm font-medium leading-none text-gray-700 mr-2'>
                            Email{' '}
                          </p>
                        </div>
                      </th>
                      <th>
                        <div className='flex items-center pl-5'>
                          <p className='text-sm font-medium leading-none text-gray-700 mr-2'>
                            Role
                          </p>
                        </div>
                      </th>
                      <th>
                        <div className='flex items-center pl-5'>
                          <p className='text-sm font-medium leading-none text-gray-700 mr-2'>
                            Mac Address
                          </p>
                        </div>
                      </th>
                      <th>
                        <div className='flex items-center pl-5'>
                          <p className='text-sm font-medium leading-none text-gray-700 mr-2'>
                            is Blocked?
                          </p>
                        </div>
                      </th>
                      <th>
                        <div className='flex items-center pl-5'>
                          <p className='text-sm font-medium leading-none text-gray-700 mr-2'>
                            Events Count
                          </p>
                        </div>
                      </th>
                      <th>
                        <div className='flex items-center pl-5'>
                          <p className='text-sm font-medium leading-none text-gray-700 mr-2'>
                            Edit
                          </p>
                        </div>
                      </th>
                      <th>
                        <div className='flex items-center pl-5'>
                          <p className='text-sm font-medium leading-none text-gray-700 mr-2'>
                            Delete
                          </p>
                        </div>
                      </th>
                    </tr>
                  </thead>

                  {currentPosts?.map((elem, index) => (
                    <tbody key={index}>
                      <tr className='h-16 border border-gray-100 rounded'>
                        <td>
                          <div className='ml-5'>
                            <div className='bg-gray-200 rounded-sm w-5 h-5 flex flex-shrink-0 justify-center items-center relative'>
                              <input type='checkbox' />
                              <div className='check-icon  bg-indigo-700 text-white rounded-sm'></div>
                            </div>
                          </div>
                        </td>
                        <td className>
                          <div className='flex items-center pl-5'>
                            <p className='text-sm   leading-none text-gray-700 mr-2'>
                              {elem.name}
                            </p>
                          </div>
                        </td>
                        <td className='pl-5'>
                          <div className='flex items-center'>
                            <p className='text-sm   leading-none text-gray-700 mr-2'>
                              {elem.email}
                            </p>
                          </div>
                        </td>
                        <td className='pl-5'>
                          <div className='flex items-center'>
                            <p className='text-sm   leading-none text-gray-700 mr-2'>
                              {elem.role}
                            </p>
                          </div>
                        </td>
                        <td className='pl-5'>
                          <div className='flex items-center'>
                            <p className='text-sm   leading-none text-gray-700 mr-2'>
                              {elem.macAddress}
                            </p>
                          </div>
                        </td>
                        <td className='pl-5'>
                          <div className='flex items-center'>
                            <p className='text-sm   leading-none text-white mr-2'>
                              {elem.isBlocked ? (
                                <span className='bg-cpurple  p-4'>Yes </span>
                              ) : (
                                <span className='bg-cgreen-400 p-4'>No </span>
                              )}
                            </p>
                          </div>
                        </td>
                        <td className='pl-5'>
                          <p className='py-3 px-3 text-sm focus:outline-none leading-none t  rounded'>
                            {elem?.eventCount}
                          </p>
                        </td>
                        <td className='pl-4'>
                          <Link
                            to={{
                              pathname: `/user/${elem.id}`,
                              propId: { userId: elem.id },
                            }}
                            className='text-sm leading-none text-gray-600 py-3 px-5 bg-gray-100 rounded hover:bg-gray-200 focus:outline-none'
                          >
                            View / Edit
                          </Link>
                        </td>

                        {/*    <td>{<Dropdown props={elem?._id} />}</td>*/}
                        <td>
                          <button
                            className='py-3 px-3 bg-red-500 text-white text-sm focus:outline-none leading-none t  rounded'
                            onClick={() => DeleteId(elem.id)}
                            href='#'
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                      <tr className='h-3' />
                    </tbody>
                  ))}
                </table>
              </div>
            </div>
          </div>
          <style>
            {` .checkbox:checked + .check-icon {
                display: flex;
            }`}
          </style>
        </div>
      </div>{' '}
      <Pagination
        postsPerPage={postsPerPage}
        totalPosts={userLists?.length}
        paginate={paginate}
        currentPage={currentPage}
      />
    </>
  );
}
