import { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { registerEventAction } from '../../redux/slices/eventSlice';

export default function StepperControls({ handleClick, currentStep, steps }) {
  const [userData, setUserData] = useState('');

  const dispatch = useDispatch();
  const handleSubmit = () => {
    console.log('us', userData);
    //  dispatch(registerEventAction(userData));

    //let values = JSON.parse(local);
  };

  let mystep = localStorage.getItem('currentStep');
  useEffect(() => {
    // alert(mystep);
  }, []);
  return (
    <div className='container flex justify-around  '>
      {currentStep !== 100 && (
        <>
          <button
            onClick={() => handleClick()}
            className={`bg-white text-slate-400 uppercase py-2 px-4 rounded-xl font-semibold cursor-pointer border-2 border-slate-300 hover:bg-slate-700 hover:text-white transition duration-200 ease-in-out ${
              currentStep === 1 ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            Back
          </button>
          <button
            onClick={() => handleClick('next')}
            className='bg-cgreen-500 text-white uppercase py-2 px-4 rounded-xl font-semibold cursor-pointer hover:bg-cyellow hover:text-cgreen-500 transition duration-200 ease-in-out'
          >
            {currentStep === steps.length - 1 ? (
              <a onClick={handleSubmit}> Confirm</a>
            ) : (
              'Next'
            )}
          </button>{' '}
        </>
      )}
    </div>
  );
}
