import { Link, Navigate } from 'react-router-dom';

import { useFormik } from 'formik';
import { StepperContexts } from './contexts/StepperContexts';
import { useContext, useState } from 'react';
import Dropzone from 'react-dropzone';
import DatePicker from 'react-datepicker';
export default function Beneficiary() {
  const { userData, setUserData } = useContext(StepperContexts);

  const handleChange = (e) => {
    const { name, value } = e.target;

    console.log(e.target.value);
    setUserData({ ...userData, [name]: value });
  };
  const handleDateChange = (name) => {
    setUserData({ ...userData, bdob: name });
  };
  return (
    <div className='flex  flex-col  px-8 gap-x-2'>
      <div className='my-6'>
        <label className='block mb-2 text-sm font-medium text-gray-700  '>
          Name *
        </label>
        <input
          value={userData['bname'] || ''}
          onChange={handleChange}
          type='text'
          id='bname'
          name='bname'
          className='shadow-sm bg-gray-100 border border-gray-300 text-black text-sm rounded-lg outline-cyellow rounded-lg block w-full p-2.5'
          placeholder='First Name Last Name'
          required
        />
      </div>

      <div className='mb-6'>
        <label className='block mb-2 text-sm font-medium text-gray-700  '>
          Date of Birth *
        </label>

        <DatePicker
          selected={userData?.bdob || ''}
          onChange={handleDateChange}
          dateFormat='dd/MM/yyy'
          excludeTimes
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode='select'
          placeholderText='Click to select a date'
        />
      </div>
      <div className='mb-6'>
        <label className='block mb-2 text-sm font-medium text-gray-700  '>
          CNIC
        </label>
        <input
          value={userData['bcnic'] || ''}
          onChange={handleChange}
          type='text'
          name='bcnic'
          id='bcnic'
          className='shadow-sm bg-gray-100 border border-gray-300 text-black text-sm rounded-lg outline-cyellow rounded-lg block w-full p-2.5'
          placeholder='CNIC'
          required
        />
      </div>

      <div className='mb-6'>
        <label className='block mb-2 text-sm font-medium text-gray-700  '>
          Designation
        </label>
        <input
          value={userData['bdesignation'] || ''}
          onChange={handleChange}
          type='text'
          name='bdesignation'
          id='bdesignation'
          className='shadow-sm bg-gray-100 border border-gray-300 text-black text-sm rounded-lg outline-cyellow rounded-lg block w-full p-2.5'
          placeholder='Designation'
          required
        />
      </div>
    </div>
  );
}
