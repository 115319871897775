import { Link, useLocation, Navigate, useParams } from 'react-router-dom';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, useCallback } from 'react';
import {
  getUserAction,
  updateUserAction,
} from '../../redux/slices/Users/userSlices';
import {
  getEventAction,
  updateEventAction,
} from '../../redux/slices/eventSlice';
import DatePicker from 'react-datepicker';
import { useDropzone } from 'react-dropzone';
export default function EventProfile() {
  const { id } = useParams();
  console.log(id);

  const dispatch = useDispatch();

  function runDispatch() {
    dispatch(getEventAction(id));
  }

  const fetchBusinesses = useCallback(() => {
    runDispatch();
  }, []);
  useEffect(() => {
    fetchBusinesses();
  }, [fetchBusinesses]);

  console.log('propsid', id);

  //select state from store

  const state = useSelector((state) => state?.events);
  console.log('pp', state);

  const { getEvent, loading, updateEvent } = state;

  const [isUpdated, setUpdated] = useState(false);

  //formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: getEvent?.id,

      c_name: getEvent?.candidate.name,
      image: getEvent?.candidate.image,
      c_address: getEvent?.candidate?.address,
      c_dob: new Date(getEvent?.candidate?.dob),
      c_mobile: getEvent?.candidate?.mobile,
      c_cnic: getEvent?.candidate?.cnic,
      c_martial: getEvent?.candidate?.martial,
      c_notes: getEvent?.candidate?.notes,
      b_name: getEvent?.beneficiary?.name,
      b_cnic: getEvent?.beneficiary?.cnic,
      designation: getEvent?.beneficiary?.designation,
      b_dob: new Date(getEvent?.beneficiary?.dob),
      ref_name: getEvent?.referral?.name,
      ref_mobile: getEvent?.referral?.mobile,
      ref_notes: getEvent?.referral?.notes,
      payment: getEvent?.payment,
      method: getEvent?.method,
      status: getEvent?.status,
      candidate_num: getEvent?.candidate_num,
      price: getEvent?.price,
      req_cast: getEvent?.requirements?.cast,
      req_pro: getEvent?.requirements?.profession,
      req_notes: getEvent?.requirements?.notes,
      deadline: new Date(getEvent?.deadline),
      duration: getEvent?.duration,
    },
    onSubmit: (values) => {
      console.log('c', values);
      const data = {
        id: values?.id,

        c_name: values?.c_name,
        image: values?.image,
        c_address: values?.c_address,
        c_dob: new Date(values?.c_dob),
        c_mobile: values?.c_mobile,
        c_cnic: values?.c_cnic,
        c_martial: values?.c_martial,
        c_notes: values?.c_notes,
        b_name: values?.b_name,
        b_cnic: values?.b_cnic,
        designation: values?.designation,
        b_dob: new Date(values?.b_dob),
        ref_name: values?.ref_name,
        ref_mobile: values?.ref_mobile,
        ref_notes: values?.ref_notes,
        payment: values?.payment,
        method: values?.method,
        status: values?.status,
        candidate_num: values?.candidate_num,
        price: values?.price,
        req_cast: values?.req_cast,
        req_pro: values?.req_pro,
        req_notes: values?.req_notes,
        deadline: new Date(values?.deadline),
        duration: values?.duration,
      };
      console.log('data', data);

      dispatch(updateEventAction(data));
      setUpdated(true);
    },
  });

  if (isUpdated) {
    console.log('isEdited', isUpdated, updateEvent);

    setTimeout(function () {
      window.location.href = '/events';
    }, 1000);
  }

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [userData, setUserData] = useState(null);
  const [autoDay, setAutoDay] = useState('');

  const handleDateChange = (name) => {
    setDateRange(name);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    //console.log(new Date().toLocaleDateString('en-US', options));

    console.log('date', Date.now());

    let x = new Date(name[0].toLocaleDateString('en-US', options.day));
    let y = new Date(name[1].toLocaleDateString('en-US', options.day));

    let Difference_In_Time = y - x;
    let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

    Difference_In_Time = y.getMonth() - x.getMonth();

    let total_months =
      (y.getFullYear() - x.getFullYear()) * 12 + (y.getMonth() - x.getMonth());

    setAutoDay(total_months);
    console.log('to', total_months);
    setUserData({ ...userData, deadline: y, duration: total_months });

    formik.setFieldValue('deadline', y);
    formik.setFieldValue('duration', total_months);
  };

  const [paths, setPaths] = useState([]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      setPaths(acceptedFiles.map((file) => URL.createObjectURL(file)));

      formik.setFieldValue('image', acceptedFiles[0]);
      setUserData({ ...userData, image: acceptedFiles[0] });
    },
    [setPaths]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
  });
  return (
    <div className='container  '>
      <div className=' mt-8 h-full bg-white rounded max-w-7xl rounded p-8  mx-auto'>
        <h1 className='text-2xl bg-white p-4 text-center font-medium text-gray-600 w-full'>
          Manage Event
        </h1>{' '}
        <section className='  bg-blueGray-50'>
          <form className=' ' onSubmit={formik.handleSubmit}>
            <div className='w-full  px-4 mx-auto'>
              <div className='flex justify-around'>
                <div required>
                  <div
                    {...getRootProps()}
                    className='border border-2 py-1 px-2'
                  >
                    <input {...getInputProps()} />
                    {paths != '' ? (
                      paths?.map((path) => (
                        <img
                          key={path}
                          src={path}
                          className='w-40 h-40 rounded-full mx-auto'
                        />
                      ))
                    ) : (
                      <img
                        src={getEvent?.candidate.image}
                        className='w-40 h-40 rounded-full mx-auto'
                      />
                    )}
                    <p>Drop/Upload the image here ...</p>
                  </div>
                </div>
                <div>
                  <h1 className='text-2xl bg-white p-4 text-center font-medium text-gray-600 w-full'>
                    Candidate Number: {getEvent?.candidate_num}
                  </h1>{' '}
                  <h1 className='text-2xl bg-white p-4 text-center font-medium text-gray-600 w-full'>
                    Status: {getEvent?.status}
                  </h1>{' '}
                  <h1 className='text-2xl bg-white p-4 text-center font-medium text-gray-600 w-full'>
                    Deadline:
                    {new Date(getEvent?.deadline).toLocaleDateString()}
                  </h1>{' '}
                  <h1 className='text-2xl bg-white p-4 text-center font-medium text-gray-600 w-full'>
                    Created By: {getEvent?.createdBy?.name}
                  </h1>{' '}
                </div>
              </div>
              <div className='my-6  flex gap-x-2'>
                <label className='block w-full mb-2 p-2 rounded-md  text-sm bg-cgreen-400 font-medium text-white  '>
                  Deadline : Duration
                  <span className='text-cgreen-400 px-4 py-2 bg-white  ml-20 mr-1 '>
                    {autoDay !== '' ? autoDay : formik.values.duration}
                  </span>
                  months
                </label>
                <div className='flex w-full  flex-col'>
                  <DatePicker
                    selected={userData?.deadline || ''}
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={handleDateChange}
                    dateFormat='dd/MM/yyy'
                    excludeTimes
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode='select'
                    placeholderText={formik.values.deadline.toLocaleDateString()}
                  />
                </div>
              </div>
              <div className='relative flex gap-x-2 justify-around   border-gray-200 w-full mb-6  '>
                <div className='p-6 w-full border-2 border-gray-100 shadow-xl rounded-lg '>
                  <p className='block mb-2 bg-cgreen-400 text-lg text-center font-medium text-white p-2  '>
                    Candidate
                  </p>
                  <div className='mb-6'>
                    <label className='block mb-2 text-sm font-medium text-gray-700  '>
                      Name
                    </label>
                    <input
                      value={formik.values.c_name}
                      onChange={formik.handleChange('c_name')}
                      onBlur={formik.handleBlur('c_name')}
                      type='text'
                      id='c_name'
                      className='shadow-sm bg-cgray border border-gray-300 text-black text-sm rounded-lg  rounded-lg block w-full p-2.5'
                      required
                    />
                  </div>
                  <div className='mb-6'>
                    <label className='block mb-2 text-sm font-medium text-gray-700  '>
                      CNIC
                    </label>
                    <input
                      value={formik.values.c_cnic}
                      onChange={formik.handleChange('c_cnic')}
                      onBlur={formik.handleBlur('c_cnic')}
                      type='text'
                      id='c_cnic'
                      className='shadow-sm bg-cgray border border-gray-300 text-black text-sm rounded-lg  rounded-lg block w-full p-2.5'
                      required
                    />
                  </div>
                  <div className='mb-6'>
                    <label className='block mb-2 text-sm font-medium text-gray-700  '>
                      Date of Birth
                    </label>
                    <DatePicker
                      {...formik.getFieldProps('c_dob')}
                      //selected={formik.values.c_dob }
                      value={formik.values.c_dob}
                      onChange={(val) => {
                        //val is the variable which contain the selected date
                        //You can set it anywhere
                        formik.setFieldValue('c_dob', val);
                      }}
                      dateFormat='dd/MM/yyy'
                      id='c_dob'
                      excludeTimes
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode='select'
                      placeholderText={formik.values.c_dob.toLocaleDateString()}
                    />
                  </div>{' '}
                  <label className='block mb-8 text-sm font-medium text-gray-700  '>
                    Martial Status
                  </label>
                  <div className='flex   justify-between'>
                    {' '}
                    <div className='   mb-6'>
                      <div className='mr-8 text-sm'></div>
                      <div className='flex items-center text-center gap-x-2 h-5'>
                        <div>
                          <label className='block mb-2 text-sm font-medium text-gray-400  '>
                            Single
                          </label>
                          <input
                            {...formik.getFieldProps('c_martial')}
                            value='single'
                            checked={formik.values.c_martial === 'single'}
                            type='radio'
                            className='w-4 h-4 bg-gray-50 rounded border border-gray-300 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800'
                          />
                        </div>
                        <div>
                          <label
                            for='manager'
                            className='block mb-2 text-sm font-medium text-gray-400  '
                          >
                            Divorced{' '}
                          </label>
                          <input
                            {...formik.getFieldProps('c_martial')}
                            value='divorced'
                            checked={formik.values.c_martial === 'divorced'}
                            type='radio'
                            className='w-4 h-4 bg-gray-50 rounded border border-gray-300 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800'
                          />
                        </div>
                      </div>
                    </div>{' '}
                    <input
                      type='text'
                      name='price'
                      onChange={formik.handleChange('c_martial')}
                      value={formik.values.c_martial}
                      placeholder='Write amount'
                      className='shadow-sm bg-cgray border border-gray-300 text-black text-sm rounded-lg   h-10 mx-auto p-1'
                      required
                    />{' '}
                  </div>{' '}
                  <div className='mb-6'>
                    <label className='block mb-2 text-sm font-medium text-gray-700  '>
                      Address
                    </label>
                    <input
                      value={formik.values.c_address}
                      onChange={formik.handleChange('c_address')}
                      onBlur={formik.handleBlur('c_address')}
                      type='text'
                      id='c_address'
                      className='shadow-sm bg-cgray border border-gray-300 text-black text-sm rounded-lg  rounded-lg block w-full p-2.5'
                      required
                    />
                  </div>
                  <div className='mb-6'>
                    <label className='block mb-2 text-sm font-medium text-gray-700  '>
                      Mobile Number
                    </label>
                    <input
                      value={formik.values.c_mobile}
                      onChange={formik.handleChange('c_mobile')}
                      onBlur={formik.handleBlur('c_mobile')}
                      type='text'
                      id='c_mobile'
                      className='shadow-sm bg-cgray border border-gray-300 text-black text-sm rounded-lg  rounded-lg block w-full p-2.5'
                      required
                    />
                  </div>
                  <div className='mb-6'>
                    <label className='block mb-2 text-sm font-medium text-gray-700  '>
                      Notes
                    </label>
                    <input
                      value={formik.values.c_notes}
                      onChange={formik.handleChange('c_notes')}
                      onBlur={formik.handleBlur('c_notes')}
                      type='text'
                      id='c_notes'
                      className='shadow-sm bg-cgray border border-gray-300 text-black text-sm rounded-lg  rounded-lg block w-full p-2.5'
                      required
                    />
                  </div>
                  <p className='block mb-2 bg-cgreen-400  text-lg text-center font-medium text-white p-2  '>
                    Requirements
                  </p>{' '}
                  <div className='flex gap-x-8'>
                    <div className='mb-6 w-full'>
                      <label className='block mb-2 text-sm font-medium text-gray-700  '>
                        Cast
                      </label>
                      <input
                        value={formik.values.req_cast}
                        onChange={formik.handleChange('req_cast')}
                        onBlur={formik.handleBlur('req_cast')}
                        type='text'
                        id='req_cast'
                        className='shadow-sm bg-cgray border border-gray-300 text-black text-sm rounded-lg  rounded-lg block w-full p-2.5'
                        required
                      />
                    </div>{' '}
                    <div className='mb-6 w-full'>
                      <label className='block mb-2 text-sm font-medium text-gray-700  '>
                        Profession
                      </label>
                      <input
                        value={formik.values.req_pro}
                        onChange={formik.handleChange('req_pro')}
                        onBlur={formik.handleBlur('req_pro')}
                        type='text'
                        id='req_pro'
                        className='shadow-sm bg-cgray border border-gray-300 text-black text-sm rounded-lg  rounded-lg block w-full p-2.5'
                        required
                      />
                    </div>{' '}
                  </div>{' '}
                  <div className='mb-6 w-full'>
                    <label className='block mb-2 text-sm font-medium text-gray-700  '>
                      Notes
                    </label>
                    <textarea
                      value={formik.values.req_notes}
                      onChange={formik.handleChange('req_notes')}
                      onBlur={formik.handleBlur('req_notes')}
                      type='text'
                      id='req_notes'
                      className='shadow-sm bg-cgray border border-gray-300 text-black text-sm rounded-lg  rounded-lg block w-full p-2.5'
                      required
                    />
                  </div>
                </div>{' '}
                <div className='p-6 w-full border-2 border-gray-100 shadow-xl rounded-lg '>
                  <p className='block mb-2 bg-cgreen-400  text-lg text-center font-medium text-white p-2  '>
                    Beneficiary
                  </p>
                  <div className='mb-6'>
                    <label className='block mb-2 text-sm font-medium text-gray-700  '>
                      Name
                    </label>
                    <input
                      value={formik.values.b_name}
                      onChange={formik.handleChange('b_name')}
                      onBlur={formik.handleBlur('b_name')}
                      type='text'
                      id='b_name'
                      className='shadow-sm bg-cgray border border-gray-300 text-black text-sm rounded-lg  rounded-lg block w-full p-2.5'
                      required
                    />
                  </div>
                  <div className='mb-6'>
                    <label className='block mb-2 text-sm font-medium text-gray-700  '>
                      Date of Birth
                    </label>

                    <DatePicker
                      {...formik.getFieldProps('b_dob')}
                      //selected={formik.values.c_dob }
                      value={formik.values.b_dob}
                      onChange={(val) => {
                        //val is the variable which contain the selected date
                        //You can set it anywhere
                        formik.setFieldValue('b_dob', val);
                      }}
                      dateFormat='dd/MM/yyy'
                      id='b_dob'
                      excludeTimes
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode='select'
                      placeholderText={formik.values.b_dob.toLocaleDateString()}
                    />
                  </div>
                  <div className='mb-6'>
                    <label className='block mb-2 text-sm font-medium text-gray-700  '>
                      CNIC
                    </label>
                    <input
                      value={formik.values.b_cnic}
                      onChange={formik.handleChange('b_cnic')}
                      onBlur={formik.handleBlur('b_cnic')}
                      type='text'
                      id='b_cnic'
                      className='shadow-sm bg-cgray border border-gray-300 text-black text-sm rounded-lg  rounded-lg block w-full p-2.5'
                      required
                    />
                  </div>
                  <div className='mb-6'>
                    <label className='block mb-2 text-sm font-medium text-gray-700  '>
                      Designation
                    </label>
                    <input
                      value={formik.values.designation}
                      onChange={formik.handleChange('designation')}
                      onBlur={formik.handleBlur('designation')}
                      type='text'
                      id='designation'
                      className='shadow-sm bg-cgray border border-gray-300 text-black text-sm rounded-lg  rounded-lg block w-full p-2.5'
                      required
                    />
                  </div>{' '}
                  <p className='block mb-2 bg-cgreen-400  text-lg text-center font-medium text-white p-2  '>
                    Referral
                  </p>
                  <div className='mb-6'>
                    <label className='block mb-2 text-sm font-medium text-gray-700  '>
                      Name
                    </label>
                    <input
                      value={formik.values.ref_name}
                      onChange={formik.handleChange('ref_name')}
                      onBlur={formik.handleBlur('ref_name')}
                      type='text'
                      id='ref_name'
                      className='shadow-sm bg-cgray border border-gray-300 text-black text-sm rounded-lg  rounded-lg block w-full p-2.5'
                      required
                    />
                  </div>
                  <div className='mb-6'>
                    <label className='block mb-2 text-sm font-medium text-gray-700  '>
                      Mobile Number
                    </label>
                    <input
                      value={formik.values.ref_mobile}
                      onChange={formik.handleChange('ref_mobile')}
                      onBlur={formik.handleBlur('ref_mobile')}
                      type='text'
                      id='ref_mobile'
                      className='shadow-sm bg-cgray border border-gray-300 text-black text-sm rounded-lg  rounded-lg block w-full p-2.5'
                      required
                    />
                  </div>
                  <div className='mb-6'>
                    <label className='block mb-2 text-sm font-medium text-gray-700  '>
                      Notes
                    </label>
                    <textarea
                      value={formik.values.ref_notes}
                      onChange={formik.handleChange('ref_notes')}
                      onBlur={formik.handleBlur('ref_notes')}
                      type='text'
                      id='ref_notes'
                      className='shadow-sm bg-cgray border border-gray-300 text-black text-sm rounded-lg  rounded-lg block w-full p-2.5'
                      required
                    />
                  </div>{' '}
                  <p className='block mb-2 bg-cgreen-400  text-lg text-center font-medium text-white p-2  '>
                    Payment Bracket
                  </p>
                  <div className='flex  flex-col  px-8 gap-x-2'>
                    <div className='flex justify-center flex-row gap-x-8'>
                      <div className='mb-6 items-center text-center   '>
                        <label className='block mb-2 text-sm font-medium text-gray-700  '>
                          Normal
                        </label>
                        <input
                          {...formik.getFieldProps('payment')}
                          checked={formik.values.payment === 'normal'}
                          value='normal'
                          type='radio'
                          name='payment'
                          className='w-4 h-4 bg-gray-50 rounded border border-gray-300 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800'
                          placeholder='First Name Last Name'
                          required
                        />
                      </div>
                      <div className='mb-6 items-center text-center '>
                        <label className='block mb-2 text-sm font-medium text-gray-700  '>
                          Sponsored
                        </label>

                        <input
                          {...formik.getFieldProps('payment')}
                          checked={formik.values.payment === 'sponsored'}
                          value='sponsored'
                          type='radio'
                          name='payment'
                          className='w-4 h-4 bg-gray-50 rounded border border-gray-300 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800'
                          placeholder='First Name Last Name'
                          required
                        />
                      </div>
                      <div className='mb-6 items-center text-center '>
                        <label className='block mb-2 text-sm text-center font-medium text-gray-700  '>
                          Customised
                        </label>
                        <input
                          {...formik.getFieldProps('payment')}
                          checked={formik.values.payment === 'customised'}
                          value='customised'
                          type='radio'
                          name='payment'
                          className='w-4 h-4 bg-gray-50 rounded border border-gray-300 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800'
                          placeholder='Write Notes'
                          required
                        />
                      </div>{' '}
                      <input
                        value={formik.values.price}
                        onChange={formik.handleChange('price')}
                        onBlur={formik.handleBlur('price')}
                        type='text'
                        name='price'
                        placeholder='Write amount'
                        className='shadow-sm bg-cgray border border-gray-300 text-black text-sm rounded-lg   h-10 mx-auto p-1'
                        required
                      />{' '}
                    </div>{' '}
                    <div className='mb-6 '>
                      <div className='flex   my-8 text-center gap-x-2 h-5'>
                        <label className='block mb-2 text-sm font-medium text-gray-700  '>
                          Payment Method
                        </label>
                        <div>
                          <label className='block mb-2 text-sm font-medium text-gray-400  '>
                            Cash
                          </label>
                          <input
                            {...formik.getFieldProps('method')}
                            checked={formik.values.method === 'cash'}
                            value='cash'
                            name='method'
                            type='radio'
                            className='w-4 h-4 bg-gray-50 rounded border border-gray-300 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800'
                            required
                          />{' '}
                        </div>
                        <div>
                          {' '}
                          <label className='block mb-2 text-sm font-medium text-gray-400  '>
                            Cheque
                          </label>{' '}
                          <input
                            {...formik.getFieldProps('method')}
                            checked={formik.values.method === 'cheque'}
                            value='cheque'
                            name='method'
                            type='radio'
                            className='w-4 h-4 bg-gray-50 rounded border border-gray-300 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800'
                            required
                          />
                        </div>{' '}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>{' '}
            <button
              type='submit'
              className='text-white bg-cpurple w-full  hover:bg-cgreen-400 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-4 text-center  '
            >
              Update User Profile
            </button>
          </form>
        </section>
      </div>
    </div>
  );
}
