import { Link, Navigate } from 'react-router-dom';
import { useCallback } from 'react';
import { useFormik } from 'formik';
import { StepperContexts } from './contexts/StepperContexts';
import { useContext, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import DatePicker from 'react-datepicker';

export default function Candidate() {
  const { userData, setUserData } = useContext(StepperContexts);
  const dispatch = useDispatch();
  const [paths, setPaths] = useState([]);
  const { imgPreview, setImgPreview } = useContext(StepperContexts);

  const onDrop = useCallback(
    (acceptedFiles) => {
      setPaths(acceptedFiles.map((file) => URL.createObjectURL(file)));

      formik.setFieldValue('image', acceptedFiles[0]);
      setUserData({ ...userData, image: acceptedFiles[0] });
    },
    [setPaths]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      dob: '',
      cnic: '',
      image: '',
      address: '',
      mobile: '',
    },
    onSubmit: (values) => {
      console.log('asd', values);
      const data = {
        name: values?.name,
        dob: values?.dob,

        address: values?.address,
        mobile: values?.mobile,
        image: values?.image,
        cnic: values?.cnic,
      };
      console.log('da', data);
      if (data !== '') {
        //   dispatch(registerCandidateAction(data));
        // setUserData(data);
      } else {
        alert('Please upload image');
      }
    },
  });
  const handleChange = (e) => {
    const { name, value } = e.target;

    console.log(e.target.value);
    formik.handleChange(name);

    setUserData({ ...userData, [name]: value });
  };

  const [startDate, setStartDate] = useState(new Date());

  const handleDateChange = (name) => {
    console.log('dnna', name.toLocaleDateString());
    setStartDate(name);
    setUserData({ ...userData, dob: name });
  };
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className='flex  flex-col  px-8 gap-x-2'>
        {' '}
        <div className='mb-6  '>
          <label className='block mb-2 text-sm font-medium text-gray-700  '>
            Upload Pic *
          </label>
          <div className='flex justify-around'>
            <div required>
              <div
                {...getRootProps()}
                className='border text-sm border-cyellow border-2 py-1 px-2'
              >
                <input {...getInputProps()} />
                <p>Drop/Upload the image here ...</p>
              </div>
            </div>
            {paths.map((path) => (
              <img key={path} src={path} className='w-10 h-10 rounded-full' />
            ))}
          </div>
        </div>
        <div className='flex gap-x-8'>
          <div className='mb-6 w-full'>
            <label className='block mb-2 text-sm font-medium text-gray-700  '>
              Name *
            </label>
            <input
              value={userData?.name || ''}
              //   value={userData?.name}
              onChange={handleChange}
              onBlur={formik.handleBlur('name')}
              type='text'
              name='name'
              className='shadow-sm bg-gray-100 border border-gray-300 text-black text-sm rounded-lg outline-cyellow rounded-lg block w-full p-2.5'
              placeholder='First Name Last Name'
              required
            />
          </div>{' '}
          <div className='mb-6 w-full'>
            <label className='block mb-2 text-sm font-medium text-gray-700  '>
              CNIC
            </label>
            <input
              value={userData?.cnic || ''}
              onChange={handleChange}
              onBlur={formik.handleBlur('cnic')}
              type='text'
              name='cnic'
              className='shadow-sm bg-gray-100 border border-gray-300 text-black text-sm rounded-lg outline-cyellow rounded-lg block w-full p-2.5'
              placeholder='CNIC'
              required
            />
          </div>
        </div>
        <div className='flex gap-x-8'>
          {' '}
          <div className='mb-6 w-3/4 '>
            <label className='block mb-2 text-sm font-medium text-gray-700  '>
              Mobile Number
            </label>
            <input
              value={userData?.mobile || ''}
              onChange={handleChange}
              onBlur={formik.handleBlur('mobile')}
              type='text'
              name='mobile'
              className='shadow-sm bg-gray-100 border border-gray-300 text-black text-sm rounded-lg outline-cyellow rounded-lg block w-full p-2.5'
              placeholder='Mobile Number'
              required
            />
          </div>
          <div className='mb-6 w-2/4'>
            <label className='block mb-2 text-sm font-medium text-gray-700  '>
              Date of Birth *
            </label>
            <DatePicker
              selected={userData?.dob || ''}
              onChange={handleDateChange}
              dateFormat='dd/MM/yyy'
              excludeTimes
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode='select'
              placeholderText='Click to select a date'
            />
          </div>
          <div className='  w-full'>
            <label className='block mb-2 text-sm font-medium text-gray-700  '>
              Marital Status
            </label>
            <div className='flex items-center my-8 text-center gap-x-8   h-5'>
              <div>
                <label className='block mb-2 text-sm font-medium text-gray-400  '>
                  Single
                </label>
                <input
                  checked={userData?.martial === 'single'}
                  onChange={handleChange}
                  value='single'
                  name='martial'
                  type='radio'
                  className='w-4 h-4 bg-gray-50 rounded border border-gray-300 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800'
                  required
                />{' '}
              </div>
              <div>
                {' '}
                <label className='block mb-2 text-sm font-medium text-gray-400  '>
                  Divorced
                </label>{' '}
                <input
                  checked={userData?.martial === 'divorced'}
                  value='divorced'
                  name='martial'
                  onChange={handleChange}
                  type='radio'
                  className='w-4 h-4 bg-gray-50 rounded border border-gray-300 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800'
                  required
                />
              </div>{' '}
              <div>
                <label className='block mb-2 text-sm font-medium text-gray-400  '>
                  Others
                </label>

                <input
                  value={userData?.martial || ''}
                  onChange={handleChange}
                  onBlur={formik.handleBlur('martial')}
                  type='text'
                  name='martial'
                  className='shadow-sm bg-gray-100 border border-gray-300 text-black text-sm rounded-lg outline-cyellow rounded-lg block w-full p-2.5'
                  placeholder='Seperation/1st Marriage etc.'
                  required
                />
              </div>{' '}
            </div>
          </div>
        </div>
        <div className='mb-6'>
          <label className='block mb-2 text-sm font-medium text-gray-700  '>
            Home Address
          </label>
          <input
            value={userData?.address || ''}
            onChange={handleChange}
            onBlur={formik.handleBlur('address')}
            type='text'
            name='address'
            className='shadow-sm bg-gray-100 border border-gray-300 text-black text-sm rounded-lg outline-cyellow rounded-lg block w-full p-2.5'
            placeholder='Address'
            required
          />
        </div>
        <div className=''>
          <label className='block mb-2 text-sm font-medium text-gray-700  '>
            Notes
          </label>
          <textarea
            value={userData?.notes || ''}
            rows='4'
            onChange={handleChange}
            onBlur={formik.handleBlur('notes')}
            type='text'
            name='notes'
            className='shadow-sm bg-gray-100 border border-gray-300 text-black text-sm rounded-lg outline-cyellow rounded-lg block w-full p-2.5'
            placeholder='Write Notes'
            required
          />
        </div>
      </div>
    </form>
  );
}
