import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { baseURL } from '../../../utils/baseURL';

export const registerUserAction = createAsyncThunk(
  'users/register',
  async (user, { rejectWithValue, getState, dispatch }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const res = await axios.post(
        `${baseURL}/api/users/register`,
        user,
        config
      );
      return res.data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const loginUserAction = createAsyncThunk(
  'users/login',

  async (user, { rejectWithValue, getState, dispatch }) => {
    try {
      console.log('userr', user);
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const { data } = await axios.post(
        `${baseURL}/api/users/login`,
        user,
        config
      );
      console.log('userinfo', data);
      localStorage.setItem('userinfo', JSON.stringify(data));
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const logoutUserAction = createAsyncThunk(
  'users/logout',
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      localStorage.removeItem('userinfo');
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);
//Get user from local Storage

const userLoginFromStorage = localStorage.getItem('userinfo')
  ? JSON.parse(localStorage.getItem('userinfo'))
  : null;

export const allUsersAction = createAsyncThunk(
  'users/lists',

  async (user, { rejectWithValue, getState, dispatch }) => {
    try {
      console.log('users', user);
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const { data } = await axios.get(`${baseURL}/api/users`, config);
      console.log('res', data);
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const deleteUserAction = createAsyncThunk(
  'user/delete',

  async (id, { rejectWithValue, getState, dispatch }) => {
    const user = getState()?.users;
    console.log('asdsa', user);
    const { userAuth } = user;
    try {
      console.log('user', user);
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const { data } = await axios.delete(`${baseURL}/api/users/${id}`, config);
      console.log('res', data);
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getUserAction = createAsyncThunk(
  'user/get',

  async (id, { rejectWithValue, getState, dispatch }) => {
    const user = getState()?.users;
    console.log('asdsa', user);
    const { userAuth } = user;
    try {
      console.log('user', user);
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const { data } = await axios.get(`${baseURL}/api/users/${id}`, config);
      console.log('res', data);
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateUserAction = createAsyncThunk(
  'user/update',

  async (user, { rejectWithValue, getState, dispatch }) => {
    // const user = getState()?.users;
    console.log('updateUserAction', user);
    const { userAuth } = user;
    try {
      console.log('uidser', user.id);
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const { data } = await axios.put(
        `${baseURL}/api/users/${user.id}`,
        {
          name: user?.name,
          macAddress: user?.macAddress,
          role: user?.role,
          isBlocked: user?.isBlocked,
        },
        config
      );
      console.log('res', data);
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

const usersSlices = createSlice({
  name: 'users',
  initialState: {
    userAuth: userLoginFromStorage,
  },
  extraReducers: (builder) => {
    //Register
    builder.addCase(registerUserAction.pending, (state, action) => {
      state.loading = true;
      state.appError = undefined;
      state.serverError = undefined;
    });

    builder.addCase(registerUserAction.fulfilled, (state, action) => {
      state.loading = false;
      state.register = action?.payload;
      state.appError = undefined;
      state.serverError = undefined;
    });

    builder.addCase(registerUserAction.rejected, (state, action) => {
      state.loading = false;
      state.appError = action?.payload?.message;
      state.serverError = action?.error?.message;
    });

    //Login
    builder.addCase(loginUserAction.pending, (state, action) => {
      state.loading = true;
      state.appError = undefined;
      state.serverError = undefined;
    });

    builder.addCase(loginUserAction.fulfilled, (state, action) => {
      state.loading = false;
      state.userAuth = action?.payload;
      state.appError = undefined;
      state.serverError = undefined;
    });

    builder.addCase(loginUserAction.rejected, (state, action) => {
      state.loading = false;
      state.appError = action?.payload?.message;
      state.serverError = action?.error?.message;
    });

    //logout user

    builder.addCase(logoutUserAction.pending, (state, action) => {
      state.loading = false;
    });

    builder.addCase(logoutUserAction.fulfilled, (state, action) => {
      state.appError = undefined;
      state.serverError = undefined;
      state.loading = false;
      state.userAuth = undefined;
    });
    builder.addCase(logoutUserAction.rejected, (state, action) => {
      state.appError = action?.payload?.message;
      state.serverError = action?.error?.message;
      state.loading = false;
    });

    //list Users
    builder.addCase(allUsersAction.pending, (state, action) => {
      state.loading = true;
      state.appError = undefined;
      state.serverError = undefined;
    });

    builder.addCase(allUsersAction.fulfilled, (state, action) => {
      state.loading = false;
      state.userLists = action?.payload;
      state.appError = undefined;
      state.serverError = undefined;
    });

    builder.addCase(allUsersAction.rejected, (state, action) => {
      state.loading = false;
      state.appError = action?.payload?.message;
      state.serverError = action?.error?.message;
    });

    //Delete User
    builder.addCase(deleteUserAction.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(deleteUserAction.fulfilled, (state, action) => {
      state.loading = false;
      state.deletedUser = action?.payload;

      state.appError = undefined;
      state.serverError = undefined;
    });

    builder.addCase(deleteUserAction.rejected, (state, action) => {
      state.loading = false;
      state.appError = action?.payload?.message;
      state.serverError = action?.error?.message;
    });

    //Get User By Id
    builder.addCase(getUserAction.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(getUserAction.fulfilled, (state, action) => {
      state.loading = false;
      state.getUser = action?.payload;

      state.appError = undefined;
      state.serverError = undefined;
    });

    builder.addCase(getUserAction.rejected, (state, action) => {
      state.loading = false;
      state.appError = action?.payload?.message;
      state.serverError = action?.error?.message;
    });

    //Update User By Id
    builder.addCase(updateUserAction.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(updateUserAction.fulfilled, (state, action) => {
      state.loading = false;
      state.updateUser = action?.payload;

      state.appError = undefined;
      state.serverError = undefined;
    });

    builder.addCase(updateUserAction.rejected, (state, action) => {
      state.loading = false;
      state.appError = action?.payload?.message;
      state.serverError = action?.error?.message;
    });
  },
});

export default usersSlices.reducer;
