import { Outlet } from 'react-router-dom';
import { Link, Navigate, useLocation } from 'react-router-dom';

import Sidebar from './components/Sidebar/Sidebar';
import { useDispatch, useSelector } from 'react-redux';

import AdminSidebar from './components/Sidebar/AdminSidebar';
import { useEffect, useState } from 'react';

export default function SidebarLayout() {
  const state = useSelector((state) => state?.users);
  console.log(state);
  let user_role = state?.userAuth?.role;
  console.log(user_role);
  const router = useLocation();
  const [pathName, setPathName] = useState('');

  useEffect(() => {
    // console.log(router);
    setPathName(router.pathname.replace('/', ''));
  }, [router]);
  return (
    <>
      <div className=' flex  '>
        {(user_role = 'Admin' ? <AdminSidebar /> : <Sidebar />)}
        <div className='flex flex-col bg-[#F5F8FA] w-full  h-full'>
          <div className='px-4  bg-cgreen-500  py-4 md:py-7'>
            <div className='flex items-center justify-between'>
              <p className='text-base sm:text-lg uppercase md:text-xl lg:text-2xl font-bold leading-normal text-white'>
                {pathName === '' ? 'Dashboard' : pathName}
              </p>

              {/*     <div className='py-3 px-4 flex items-center text-sm font-medium leading-none text-gray-600 bg-gray-200 hover:bg-gray-300 cursor-pointer rounded'>
                <p>Sort By:</p>
                <select className='focus:outline-none bg-transparent ml-1'>
                  <option className='text-sm text-indigo-800'>Latest</option>
                  <option className='text-sm text-indigo-800'>Oldest</option>
                  <option className='text-sm text-indigo-800'>Latest</option>
                </select>
  </div> */}
            </div>
          </div>
          <Outlet />
          <footer className='   mx-auto  text-center  pb-6  '>
            <img src='../ib.png' className='w-6 h-6 mx-auto ' />
            <div className='text-sm    text-blueGray-500 font-semibold py-1'>
              <span> Designed & Developed By &nbsp; </span>
              <a
                href='https://itbrain.com.pk'
                className='text-blueGray-500 hover:text-gray-800'
                target='_blank'
              >
                IT Brain
              </a>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
}
